const components = new Map();

components.set('content', 'Content');
components.set('media', 'MediaBlock');
components.set('repeater', 'Repeater');
components.set('list', 'List');
components.set('archive', 'Archive');
components.set('placeholder', 'Placeholder');
components.set('ctas', 'Ctas');

export default components;
