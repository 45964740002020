<template>
  <button :class="['chip', 'black-ext', 'typo--s', data.style ? `chip-${data.style}` : 'chip-default']">
    <Spacer template="chip">
      {{ data.value }}
    </Spacer>
  </button>
</template>

<script>
import Spacer from '@/components/layout/spacer';

export default {
  name: 'Chip',
  components: {
    Spacer,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  border-radius: 15px;
  min-width: 120px;

  &.chip-default {
    background: $white;
    border: 1px solid $black;
  }
  &.chip-primary {
    background: $primary-color;
    border: 1px solid $primary-color;
  }
}
</style>
