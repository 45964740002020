<template>
  <Column
    :size="data.section_layout === 'grid-3-left' || data.section_layout === 'grid-3-right' ? {} : data.section_layout !== 'half-half' ? '12' : '6'"
    :class="[(data.section_layout === 'default' && !isMobile) || data.section_layout === 'cover' ? 'no-gutter' : false]"
  >
    <Wrapper :size="data.type === 'image' && data.section_layout === 'default' ? 'l' : false">
      <MediaComponent
        v-if="data[data.type]"
        ref="media"
        :data="data"
      />
    </Wrapper>
  </Column>
</template>

<script>
import { mapGetters } from 'vuex';
import MediaComponent from '@/components/media/index';

export default {
  name: 'MediaBlock',
  components: {
    MediaComponent,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>

<style>

</style>
