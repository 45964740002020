<template>
  <div
    :class="['notifications', $route.name === 'Home' ? 'notifications-fix' : false]"
    :style="fixBottom > 0 ? { marginBottom: `${fixBottom}px` } : false"
  >
    <Notification
      v-for="(notification, index) in parseNotifications"
      :key="index"
      :data="notification"
    />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import Notification from '@/components/ui/notification';

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  data() {
    return {
      parseNotifications: [],
      fixBottom: 0,
    };
  },
  computed: {
    ...mapGetters(['notifications', 'isMobile']),
  },
  watch: {
    notifications: {
      deep: true,
      immediate: true,
      handler(val) {
        this.parseNotifications = val.filter(
          (notification) => notification.status && this.checkBreakpoint(notification.breakpoint),
        );
      },
    },
    isMobile() {
      this.$nextTick(() => {
        this.parseNotifications = this.notifications.filter(
          (notification) => notification.status && this.checkBreakpoint(notification.breakpoint),
        );
      });
    },
  },
  created() {
    setTimeout(() => {
      this.$store.commit('ADD_NOTIFICATION', {
        id: 'newsletter',
        status: !Cookies.get('april-newsletter'),
        ...this.$store.state.wp.options.notifications.newsletter,
      });
    }, 1000 * 60 * 2);
  },
  mounted() {},
  methods: {
    checkBreakpoint(val) {
      let check = false;
      if (val === 'mobile') check = this.isMobile;
      else if (val === 'desktop') check = !this.isMobile;
      else if (val === 'all') check = true;

      return check;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1001;

  @include mq($until: m) {
    right: auto;
    left: 50%;
    bottom: 0;
    transform: translate3d(-50%, 0, 0);
  }

  &-fix {
    /* @include mq($until: m) {
      bottom: 88px;
    } */
  }
}
</style>
