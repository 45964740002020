<template>
  <Spacer
    :left="!extraClass.includes('fuoritempo-gallery') ? { m: 'xxs' } : false"
    :class="['gallery', ...extraClass]"
  >
    <div
      ref="gallery"
      data-cursor="Drag"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        class="item"
      >
        <Figure
          :ref="index"
          :data="{ default: item }"
        />
      </div>
    </div>

    <div class="cursor">
      <div class="prev">
        <Icon
          name="left"
          :fn="goPrev"
        />
      </div>
      <div class="next">
        <Icon
          name="right"
          :fn="goNext"
        />
      </div>
    </div>
  </Spacer>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';
import Spacer from '@/components/layout/spacer';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
    Spacer,
    Icon,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    extraClass: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        wrapAround: true,
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false,
        autoPlay: true,
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeDestroy() {
    this.gallery.destroy();
  },
  methods: {
    goPrev() {
      this.gallery.previous();
    },
    goNext() {
      this.gallery.next();
    },
  },
};
</script>

<style lang="scss">
.gallery {
  position: relative;
  overflow: hidden;
  margin-left: $unit * -0.25;

  @include mq($until: m) {
    margin-left: 0;
  }

  .flickity-viewport {
    overflow: visible;

    &[data-dir="prev"] {
      cursor: w-resize;
    }
    &[data-dir="next"] {
      cursor: e-resize;
    }
  }

  .item {
    width: 40vw;
    margin-right: $unit * 0.25;

    @include mq($until: m) {
      width: 75vw;
    }
  }

  &.fuoritempo-gallery {
    margin-left: 0;
    .item {
      @include mq($until: m) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  @include mq($until: m) {
    display: none;
  }

  .prev,
  .next {
    cursor: pointer;
    pointer-events: auto;
    padding: 30px;
  }

  button {
    height: auto;
  }
}
</style>
