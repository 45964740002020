var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cover-wrapper"},[(!(_vm.$route.meta.transition === 'from-home' && !_vm.home))?_c('section',{class:['cover']},[_c('MediaComponent',{ref:"videoCover",attrs:{"data":Object.assign(
          {},
          _vm.data,
          { video: Object.assign({}, _vm.data.video, {loop: _vm.home ? false : true, home: _vm.home, cover: true}) },
          { image: Object.assign({}, _vm.data.image, {loop: _vm.home ? false : true, home: _vm.home, cover: true}) }
        ),"vh":true,"cursor":_vm.cursor}})],1):_vm._e(),(_vm.data.video.vimeo_full && (!_vm.home || _vm.april))?_c('section',{key:_vm.data.video.vimeo_full.uri,class:[
      'cover',
      'cover-embed',
      _vm.$route.meta.transition === 'from-home' && !_vm.home ? 'cover-embed-fx' : false ]},[(_vm.data.video.vimeo_full && !_vm.home && (_vm.isMobile || _vm.isTouch || _vm.isTouch))?_c('Cta',{directives:[{name:"show",rawName:"v-show",value:(!_vm.videoEmbed),expression:"!videoEmbed"}],staticClass:"cover-cta",attrs:{"data":{ title: 'Play full video', size: 's', fn: function () {} }}}):_vm._e(),_c('div',{class:['status'],on:{"click":_vm.setSeek}},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"progress",style:({ transform: ("scaleX(" + _vm.progress + ")") })})])]),_c('MediaComponent',{ref:"fullVideo",attrs:{"data":Object.assign({}, _vm.data, {
          video: Object.assign({}, _vm.data.video, {teaser: null, src: null, embedMode: true, cover: true}),
        }),"vh":true}}),(_vm.data.video.vimeo_full && (!_vm.home || _vm.april) && _vm.videoEmbed)?_c('div',{staticClass:"ui-bottom"},[_c('Spacer',{attrs:{"template":"header"}},[_c('div',{staticClass:"ui-bottom-wrapper"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.millisToMinutesAndSeconds(_vm.current))+"/"),_c('span',[_vm._v(_vm._s(_vm.millisToMinutesAndSeconds(_vm.videoEmbed.duration)))])])])])],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }