<template>
  <component
    :is="data.tag"
    :class="['label', `typo--${data.size}`]"
    v-html="data.value"
  />
</template>

<script>
export default {
  name: 'Label',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
        tag: 'h3',
        size: 'm',
      }),
    },
  },
};
</script>
