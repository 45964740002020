<template>
  <nav class="offcanvas">
    <Spacer :x="{ default: 's', m: 'l' }">
      <Grid
        :col="{ default: 12, m: 6 }"
        :extra-class="['row-gap-offcanvas']"
        class="offcanvas-wrapper"
      >
        <ul>
          <li
            v-for="item in primary"
            :key="item.id"
          >
            <Cta :data="{ title: item.content, size: 'xl', url: item.url }" />
          </li>
        </ul>
        <ul>
          <li
            v-for="item in secondary"
            :key="item.id"
          >
            <Cta :data="{ title: item.content, size: 'xl', url: item.url }" />
          </li>
        </ul>
      </Grid>
    </Spacer>

    <Spacer
      class="chat-wrapper"
      :x="{ default: 's', m: 'l' }"
    >
      <Chat />
    </Spacer>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Chat from '@/components/custom/chat';

export default {
  name: 'Menu',
  components: {
    Cta,
    Chat,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['menu']),
    primary() {
      const nav = this.menu('offcanvas-primary');
      return nav ? nav.items : null;
    },
    secondary() {
      const nav = this.menu('offcanvas-secondary');
      return nav ? nav.items : null;
    },
  },
};

</script>

<style lang="scss" scoped>
.offcanvas {
  background: $black;
  color: var(--color, $white);
  height: calc(100 * var(--vh, 1vh));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow: auto;

  &-wrapper {
    position: relative;
    padding-top: 93px;

    @include mq(m) {
      padding-top: 150px;
    }
  }
}

.chat-wrapper {
  position: fixed;
  width: 100%;
  bottom: $unit * 1.5;
}
</style>
