import Medusa from '@adoratorio/medusa';

require('intersection-observer');

const medusa = {
  ref: null,
  init: () => {
    medusa.ref = new Medusa({
      targets: [],
      debug: true,
    });
  },
};

export default medusa;
