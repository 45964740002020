var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    (!_vm.projectInHome && _vm.$route.params.project) ||
      (_vm.$route.params.project &&
        _vm.$store.state.route.from &&
        _vm.$store.state.route.from.pillar &&
        !_vm.$store.state.route.from.project)
      ? 'in-overlay'
      : false,
    _vm.$route.name === 'Home' ? 'is-home' : false ],attrs:{"id":"app"}},[(_vm.isHomeReady)?_c('AppHeader'):_vm._e(),_c('keep-alive',[_c('main',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loader),expression:"!loader"}],ref:"home",class:['main-home']},[_c('Home')],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'Home' || _vm.archiveStatus),expression:"$route.name !== 'Home' || archiveStatus"}],ref:"overlay",class:[
      'main-overlay',
      _vm.$route.meta.transition === 'from-home' && _vm.cover ? 'add-before' : false,
      (!_vm.projectInHome && _vm.$route.params.project) ||
        (_vm.$route.params.project &&
          _vm.$store.state.route.from &&
          _vm.$store.state.route.from.pillar &&
          !_vm.$store.state.route.from.project)
        ? 'overlay-fixed-project'
        : false ],attrs:{"data-pillar":_vm.$route.params.pillar || 'archive'}},[_c('div',[_c('transition',{attrs:{"appear":"","css":false},on:{"enter":_vm.enter,"leave":_vm.leave}},[(
            _vm.isHomeReady && _vm.$route.name !== 'Home' && !_vm.noContent.includes(_vm.$route.params.pillar)
          )?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.projectInHome),expression:"!projectInHome"}],key:_vm.prevPath,ref:"pillar",attrs:{"name":"default"}}):_vm._e()],1),_c('transition',{attrs:{"appear":"","css":false},on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.$route.params.project && _vm.$route.name !== 'Home' && _vm.isHomeReady)?_c('router-view',{key:_vm.$route.fullPath,ref:"projectRef",attrs:{"name":"overlay"}}):_vm._e()],1)],1)]),(!_vm.loader && !_vm.isTouch)?_c('AppCursor'):_vm._e(),(!_vm.loader)?_c('Notifications'):_vm._e(),_c('div',{staticClass:"disable"}),_c('transition',{attrs:{"appear":"","css":false},on:{"enter":_vm.enterArchive,"leave":_vm.leaveArchive}},[(_vm.menuStatus)?_c('AppOffCanvas',{ref:"offcanvas"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }