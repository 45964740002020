var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'video-wrapper',
    _vm.fallbackFs ? 'video-wrapper-fallback' : false,
    _vm.data.cover ? 'video-wrapper-cover' : false,
    { 'video-half': _vm.data.section_layout === 'half-half' }
  ],style:(_vm.data.cover && !_vm.isMobile && !_vm.isTouch
    ? {
      '--iframe-height': ((100 * (_vm.video.height / _vm.video.width)) + "vw"),
      '--iframe-min-height': ((100 * (100 / (100 * (_vm.video.height / _vm.video.width)))) + "vh"),
    }
    : false)},[_c('div',{staticClass:"video-player-wrapper"},[(_vm.video)?_c('div',{class:[
        'video-player',
        _vm.vh ? 'full-height' : false,
        _vm.data.embedMode || _vm.data.vimeo_full ? 'embed' : false,
        _vm.data.vimeo_full && !_vm.data.cover ? 'full' : false ],attrs:{"data-embed":_vm.data.embedMode ? 'true' : 'false',"data-cursor":_vm.videoCursor ? _vm.videoCursor : null},on:{"click":function($event){!_vm.videoInline ? _vm.toggleFullVideo() : null}}},[_c('div',{staticClass:"wrapper",style:({ paddingBottom: !_vm.vh ? (_vm.ratio + "%") : false })},[(!_vm.isMobile &&
            !_vm.isTouch &&
            ((_vm.data.embedMode && _vm.data.vimeo_full && _vm.data.cover) ||
              (_vm.data.vimeo_full && !_vm.data.cover)) &&
            _vm.id
          )?_c('div',{ref:"plyr",staticClass:"plyr-app",attrs:{"data-plyr-provider":"vimeo","data-plyr-embed-id":_vm.id}}):_vm._e(),(!(
            (_vm.data.embedMode && _vm.data.vimeo_full && _vm.data.cover) ||
            (_vm.data.vimeo_full && !_vm.data.cover)
          )
          )?_c('video',{ref:"plyr",staticClass:"plyr-app",attrs:{"preload":"metadata","playsinline":"","loop":_vm.data.loop || _vm.videoInline,"autoplay":_vm.videoInline ||
            (!!_vm.data.loop && !_vm.data.embedMode && _vm.data.cover)
            ? 'autoplay'
            : false},domProps:{"muted":!_vm.audio || _vm.videoInline ? 'muted' : false}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}})]):_vm._e()])]):_vm._e(),((!_vm.data.cover &&
        _vm.data.vimeo_full &&
        (_vm.isMobile || _vm.isTouch || _vm.isTouch)) ||
        _vm.fallbackFs
      )?_c('Cta',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showUi || _vm.plyr.paused),expression:"!showUi || plyr.paused"}],staticClass:"cover-cta",attrs:{"data":{ title: 'Play full video', size: 's', fn: function () { } }}}):_vm._e(),((_vm.isMobile || _vm.isTouch) &&
        ((_vm.data.embedMode && _vm.data.vimeo_full && _vm.data.cover) ||
          (_vm.data.vimeo_full && !_vm.data.cover))
      )?_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullVideo || !_vm.data.cover),expression:"fullVideo || !data.cover"}],ref:"videoMobile",class:[
        'video-mobile',
        !_vm.data.cover ? 'video-mobile-visible' : false,
        _vm.fallbackFs ? 'video-fallback' : false ],attrs:{"preload":"metadata","poster":!_vm.data.cover || _vm.fallbackFs
        ? _vm.video.pictures
          ? _vm.video.pictures.sizes[_vm.video.pictures.sizes.length - 1]
            .link
          : null
        : null}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}})]):_vm._e(),(_vm.fallbackFs)?_c('div',{staticClass:"close"},[_c('Icon',{attrs:{"name":"close","fn":_vm.closeFallback}})],1):_vm._e(),(!_vm.data.cover && _vm.data.vimeo_full && _vm.showUi)?_c('div',{staticClass:"video-ui"},[_c('div',{ref:"status",class:['status'],on:{"click":_vm.setSeek}},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"progress",style:({ transform: ("scaleX(" + _vm.progress + ")") })})])]),_c('div',{staticClass:"ui-bottom"},[_c('Spacer',{attrs:{"template":"video"}},[_c('div',{staticClass:"ui-bottom-wrapper"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.millisToMinutesAndSeconds(_vm.current))+"/"),_c('span',[_vm._v(_vm._s(_vm.millisToMinutesAndSeconds(_vm.plyr.duration)))])])])])],1)]):_vm._e()],1),(_vm.data.caption && !_vm.data.cover)?_c('figcaption',[_c('Spacer',{attrs:{"y":"xs"}},[_c('Label',{attrs:{"data":{ value: _vm.data.caption, tag: 'h3', size: 's' }}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }