import gsap, { ScrollToPlugin } from 'gsap/all';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

gsap.registerPlugin(ScrollToPlugin);

const transitions = {
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION_STATUS', true);
      disableBodyScroll(document.querySelector('.disable'));

      const checkCover = el.dataset.cover === 'true';

      const mainContents = Array.from(el.querySelectorAll('.main-content'));
      const mainContent = mainContents[mainContents.length - 1];

      const offset = !checkCover ? window.innerHeight : this.$store.state.delta;

      const tl = gsap.timeline({
        onComplete: () => {
          // clearAllBodyScrollLocks();
          enableBodyScroll(document.querySelector('.disable'));
          this.$store.commit('SET_TRANSITION_STATUS', false);

          done();
        },
      });

      const cover = el.querySelector('.cover-wrapper');

      const notFromArchive = this.$router.name !== 'Projects';

      if (
        notFromArchive
        && this.$route.meta.transition === 'default'
        && cover
        && this.$store.state.route.from
        && this.$store.state.route.from.name !== 'Home'
      ) {
        gsap.set(el, {
          y: window.innerHeight - this.$store.state.delta,
        });
      }

      if (notFromArchive) {
        gsap.set(mainContent, {
          y: offset,
        });
      }

      if (this.$route.meta.transition === 'from-home') {
        const pageY = this.$refs.home.getBoundingClientRect().y;

        if (!this.isMobile) {
          window.scroll(0, 0);

          gsap.set(this.$refs.home, {
            position: 'fixed',
            y: pageY,
          });
          this.$nextTick(() => {
            const scroll = this.homeItem.getBoundingClientRect().y;
            tl.add(
              gsap.to(this.$refs.home, {
                y: `-=${scroll}`,
                duration: Math.max(0.4, Math.abs(scroll) / window.innerHeight),
                ease: 'power2.out',
              }),
              0,
            );
          });
        } else {
          gsap.set(this.$refs.home, {
            position: 'fixed',
          });
        }
      } else {
        // gsap.set(this.$refs.home, {
        //   clearProps: 'all',
        // });
      }

      if (
        this.$route.meta.transition === 'default'
        && cover
        && this.$store.state.route.from
        && this.$store.state.route.from.name !== 'Home'
      ) {
        tl.add(
          gsap.to(el, {
            y: 0,
            duration: 0.4,
            ease: 'power2.out',
            clearProps: 'y',
          }),
          0,
        );
      }

      tl.add(
        gsap.to(mainContent, {
          y: 0,
          duration: 0.4,
          ease: 'power2.out',
          clearProps: 'y',
        }),
        0.5,
      );

      return tl;
    },
    leave(el, done) {
      done();
    },
    goHome() {
      const tl = gsap.timeline();
      tl.to(
        this.$refs.overlay,
        {
          y: window.innerHeight * -0.6666,
          ease: 'power2.out',
          duration: 0.4,
          clearProps: 'y',
        },
        0.2,
      );

      tl.then(() => {
        let route = '/';
        if (!this.projectInHome && this.$refs.projectRef) {
          route = `/${this.$route.params.pillar}`;
        } else if (this.homeVideo && this.homeVideo.id && this.homeVideo.paused) this.homeVideo.play();
        this.$router.push(route);

        gsap.set(this.$refs.overlay, {
          clearProps: 'y',
        });
        gsap.set('.back', {
          clearProps: 'all',
        });
      });
    },
    enterArchive(el, done) {
      const scroll = window.pageYOffset;
      this.$store.commit('SET_PREV_SCROLL', scroll);

      gsap.set(el, {
        xPercent: 100,
      });

      gsap.to(el, {
        xPercent: 0,
        duration: 0.4,
        ease: 'power2.out',
        onComplete: () => {
          done();
          document.body.classList.add('body-archive');
          this.$store.commit('SET_ARCHIVE_STATUS', true);
          this.$store.commit('SET_ARCHIVE_PREV', this.$store.state.route.from.path);
        },
        clearProps: 'all',
      });
    },
    leaveArchive(el, done) {
      document.body.classList.remove('body-archive');
      window.scroll(0, this.$store.state.prevScroll);
      gsap.to(el, {
        xPercent: 100,
        duration: 0.4,
        ease: 'power2.out',
        onComplete: () => {
          this.$store.commit('SET_ARCHIVE_STATUS', false);
          this.$store.commit('SET_ARCHIVE_PREV', null);
          done();
        },
      });
    },
    leaveLoader() {
      const loader = document.querySelector('#loader');
      gsap.to(loader, {
        clipPath: 'inset(50% 0px 50% 0px)',
        webkitClipPath: 'inset(50% 0px 50% 0px)',
        duration: 0.5,
        ease: 'power2.out',
        onComplete: () => {
          this.$store.commit('SET_LOADER', false);
        },
      });
    },
  },
};

export default transitions;
