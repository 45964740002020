import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import PlaceholderHome from '@/views/PlaceholderHome.vue';
// import Projects from '@/views/Projects.vue';
import NotFound from '@/views/404.vue';

Vue.use(VueRouter);

// eslint-disable-next-line no-undef
const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

const routes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Home',
    component: PlaceholderHome,
  },
  // {
  //   path: '/Projects',
  //   name: 'Projects',
  //   component: Projects,
  //   props: {
  //     default: () => ({ template: 'pillar', slug: 'projects', type: 'pages' }),
  //   },
  // },
  {
    path: '/:pillar/:project?',
    name: 'Page',
    components: {
      default: Page,
      overlay: Page,
    },
    meta: {
      Projects: false,
    },
    props: {
      default: (route) => ({ template: 'pillar', slug: route.params.pillar, type: 'pages' }),
      overlay: (route) => ({ template: 'project', slug: route.params.project, type: 'posts' }),
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return false;
    }
    // if (to.hash) {
    //   return {
    //     selector: to.hash,
    //     behavior: 'smooth',
    //     offset: { y: 105 },
    //   };
    // }

    let scroll = { x: 0, y: 0 };
    if ((to.params && to.params.pillar === 'project') || (from.params && from.params.pillar === 'project')) {
      scroll.y = 0;
    } else if (to.name === 'Home') {
      scroll.y = store.state.home.item ? store.state.home.item.getBoundingClientRect().y + window.pageYOffset : 0;
    } else if (
      (to.params.project && from.params.pillar && !from.params.project)
      || from.name === 'Home'
      || (from.params.project && to.params.pillar && !to.params.project)
    ) {
      scroll = false;
    } else {
      scroll.y = 0;
    }
    return scroll;
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === '404') {
    document.title = `${siteTitle} — 404`;
    next();
    return;
  }

  store.commit('SET_TRANSITION_STATUS', true);
  store.commit('SET_MENU', false);

  let slug = '';
  let type = 'pages';

  if (to.params.project) {
    slug = to.params.project;
    type = 'posts';
  } else if (to.params.pillar) {
    slug = to.params.pillar;
  } else if (
    to.matched
    && to.matched[0]
    && to.matched[0].props
    && to.matched[0].props.default
    && to.matched[0].props.default.slug
  ) {
    slug = to.matched[0].props.default.slug;
  }

  const request = {
    type,
    slug,
  };

  // const { noContent } = store.state;
  to.meta.transition = 'default';

  if (from.name === 'Home') {
    to.meta.transition = 'from-home';
  }

  // if (to.params.pillar === 'project') {
  //   to.meta.transition = 'to-Projects';
  // }

  // if (from.params.pillar === 'project' && to.params.project) {
  //   to.meta.transition = 'from-projects';
  // }

  if (to.name === 'Home') {
    document.title = `${siteTitle} — ${description}`;
    next();
  } else {
    const promises = [];

    promises.push(store.dispatch('getSingleBySlug', request));

    // if (to.params.project) {
    //   const pillarRequest = {
    //     slug: to.params.pillar,
    //     type: 'pages',
    //   };
    //   promises.push(store.dispatch('getSingleBySlug', pillarRequest));
    // }

    Promise.all(promises).then((pages) => {
      if (pages) {
        if (
          (!store.state.projectInHome && to.params.project)
          || (to.params.project && from && from.pillar && !from.project)
        ) {
          disableBodyScroll(document.querySelector('.disable'));
        } else {
          clearAllBodyScrollLocks();
        }

        document.title = `${pages[0].title.rendered} — ${siteTitle}`;
        next();
      } else {
        console.log('404');
        router.push('/');
      }
    });
  }
});

export default router;
