var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Spacer',{attrs:{"bottom":"l"}},[_c('div',{class:['input-wrapper', _vm.isMobile ? 'typo--l' : 'typo--m']},[_c('Spacer',{attrs:{"y":"xxs"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.s),expression:"s"}],ref:"input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.s)},on:{"input":function($event){if($event.target.composing){ return; }_vm.s=$event.target.value}}}),_c('Icon',{staticClass:"icon",attrs:{"name":"search","fn":_vm.submitSearch}})],1)],1)]),_c('Spacer',{attrs:{"bottom":"l"}},[_c('Flex',{staticClass:"tags"},_vm._l((_vm.tags),function(tag){return _c('div',{key:tag.term_id,class:['tag', _vm.filterTags.includes(tag.term_id) ? 'active' : 'not-active'],on:{"click":function () { return _vm.toggleTag(tag.term_id); }}},[_c('Spacer',{attrs:{"right":"xs","bottom":"s","use-margin":true}},[_c('Chip',{attrs:{"data":{ value: tag.name }}})],1)],1)}),0)],1),(_vm.request)?_c('Results',{key:JSON.stringify(_vm.request),attrs:{"custom-placeholder":true,"data":{
      archive_type: _vm.placeholder.length > 0 && _vm.request.posts ? 'posts' : 'archive',
      archive: 'posts',
      tags: _vm.request.tags,
      search: _vm.request.search,
      posts: _vm.request.posts,
      style: {
        type: 'default',
        classes: '',
      },
      layout: [
        { media_query: 'default', layout_size: '6', layout_type: 'grid' },
        { media_query: 'm', layout_size: '3', layout_type: 'grid' } ]
    },"callback":_vm.setReady,"slug":"archive"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }