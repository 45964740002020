<template>
  <Wrapper>
    <Spacer :x="{ default: 's', m: 'l' }">
      <Grid :extra-class="['fuoritempo-grid', 'column-custom-l']">
        <Column
          size="1"
          class="u-hidden--until-m"
        />
        <Column
          :size="{ default: 12, m: 5 }"
          class="fuoritempo-col-media"
        >
          <Grid
            col="12"
          >
            <Title
              :data="{ value: post.title.rendered, size: 'm', tag: 'h2' }"
              class="sticky"
            />
            <Spacer :bottom="{ m: 's'}">
              <MediaComponent
                v-if="data.type !== 'gallery'"
                :data="data"
              />

              <template v-if="!isMobile && data.type === 'gallery'">
                <div
                  v-for="(media, index) in data.gallery"
                  :key="index"
                >
                  <Spacer :top="{ default: 'xs', m: 's'}">
                    <MediaComponent
                      :data="Object.assign({}, { type: 'image', image: { default: media } })"
                    />
                  </Spacer>
                </div>
              </template>
              <template v-else-if="data.gallery">
                <Gallery
                  :data="data.gallery"
                  :extra-class="['fuoritempo-gallery']"
                  :extra-settings="{ autoPlay: true }"
                />
              </template>
            </Spacer>
          </Grid>
        </Column>
        <Column
          :size="{ default: 12, m: 6 }"
          class="fuoritempo-col-text"
        >
          <Spacer
            bottom="s"
            class="text"
          >
            <RichText
              v-if="data.rich_text !== ''"
              :data="{ value: data.rich_text, size: 'l' }"
            />
            <Spacer
              top="s"
            >
              <Label :data="{ value: data.credits, tag: 'h6', size: 's' }" />
            </Spacer>
          </Spacer>
        </Column>
      </Grid>
      <Spacer
        v-if="$route.params.pillar === 'fuoritempo'"
        top="m"
        bottom="l"
      >
        <Related
          :slug="slug"
          :data="{
            archive_type: 'archive',
            archive: 'posts',
            archive_category: !post.acf.related || (post.acf.related && post.acf.related.length === 0) ? { taxonomy: 'pillar', term_id: 4 } : undefined,
            exclude: [post.id],
            include: post.acf.related && post.acf.related.length > 0 ? post.acf.related : undefined,
            max_posts: post.acf.related && post.acf.related.length > 0 ? post.acf.related.length : 6,
            style: {
              type: 'default',
              classes: '',
            },
            layout: [
              { media_query: 'default', layout_size: '6', layout_type: 'grid' },
              { media_query: 'm', layout_size: '2', layout_type: 'grid' },
            ]
          }"
        />
      </Spacer>
    </Spacer>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import MediaComponent from '@/components/media';
import Gallery from '@/components/media/gallery';
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Related from '@/components/blocks/archive';

export default {
  name: 'Fuoritempo',
  components: {
    RichText,
    MediaComponent,
    Gallery,
    Title,
    Related,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  computed: {
    checkMedia() {
      return this.data.gallery || this.data.image?.default || this.data.image?.svg || this.data.video?.embed || this.data.video?.src || this.data.video?.tease;
    },
    ...mapGetters(['isMobile', 'pillars']),
  },
};
</script>

<style lang="scss">
.fuoritempo-grid {
  @include mq(m) {
    min-height: calc((100 * var(--vh, 1vh)) - #{$unit});

    .fuoritempo-col-text {
      min-height: calc((100 * var(--vh, 1vh)) - #{$unit});

      .text {
        top: $unit;
        position: sticky;
      }
    }

    .fuoritempo-col-media {
      min-height: calc((100 * var(--vh, 1vh)) - #{$unit});

      & > div {
        height: 100%;

        & > .grid {
          height: 100%;
          justify-content: space-between;
          grid-template-rows: 1fr;
        }
      }
    }

    .credits {
      position: absolute;
      bottom: 0;
    }
  }

  .sticky {
    position: sticky;
    top: $unit;
    padding-bottom: $unit;
    z-index: 2;
    // mix-blend-mode: difference;
    // color: $white;
    align-self: flex-start;

    @include mq($until: m) {
      padding-left: 56px;
      position: relative;
      top: $unit * 0.5;
    }
  }
}
</style>
