<template>
  <div class="marquee-wrapper">
    <Flex
      ref="marquee"
      class="marquee"
      :data-direction="direction"
    >
      <div
        v-for="(item, itemIndex) in parsedItems"
        :key="itemIndex"
        class="item"
      >
        <Spacer x="xxl">
          <img
            ref="img"
            :src="item.url"
          >
        </Spacer>
      </div>
    </Flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gsap from 'gsap';

export default {
  name: 'Marquee',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
    direction: {
      type: String,
      default: 'left',
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      playing: false,
      dir: -1,
      switchDuplicate: false,
      duplicateItems: [],
      totalWidth: 0,
      newTotalWidth: 0,
      delta: 0,
      x: 0,
      speed: 0.5,
    };
  },
  computed: {
    ...mapGetters(['isHomeReady']),
    parsedItems() {
      return this.switchDuplicate ? this.duplicateItems : this.items;
    },
  },
  watch: {
    $route(to) {
      if (to.name !== 'Home') this.stop();
      if (to.name === 'Home') this.start();
    },
    loaded(val) {
      if (val) {
        this.setDuplicateItems();
      }
    },
    switchDuplicate(val) {
      if (val) {
        this.$nextTick(() => {
          this.newTotalWidth = this.setTotalWidth();
          if (this.$route.name === 'Home' && !this.playing) this.start();
        });
      }
    },
    isHomeReady(val) {
      if (val) {
        this.$nextTick(() => {
          this.init();
        });
      }
    },
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    init() {
      this.dir = this.direction === 'right' ? 1 : -1;

      const promises = [];
      if (this.$refs.img && this.$refs.img.length > 0) {
        this.$refs.img.forEach((img) => {
          promises.push(img.decode());
        });
      }

      Promise.all(promises).then(() => {
        this.totalWidth = this.setTotalWidth();
        this.loaded = true;
      });

      this.$bus.$on('windowResized', this.resize);
    },
    setTotalWidth() {
      let width = 0;
      Array.from(this.$refs.marquee.$el.children).forEach((el) => {
        width += el.getBoundingClientRect().width;
      });

      return width;
    },
    setDuplicateItems() {
      this.duplicateItems = [];
      this.delta = Math.floor(window.innerWidth / this.totalWidth) + 2;
      for (let index = 0; index < this.delta; index += 1) {
        this.duplicateItems.push(...this.items);
      }
      this.switchDuplicate = true;
    },
    start() {
      gsap.set(this.$refs.marquee.$el, {
        clearProps: 'all',
      });
      this.x = 0;

      if (this.dir > 0) {
        gsap.set(this.$refs.marquee.$el, {
          marginLeft: (this.newTotalWidth / this.delta) * -1,
        });
      }
      this.playing = true;
      this.$aion.add(this.animate, `marquee-${this.index}`);
    },
    stop() {
      this.playing = false;
      this.$aion.remove(`marquee-${this.index}`);
    },
    animate() {
      this.x = Math.abs(this.x) >= this.newTotalWidth / this.delta
        ? 0
        : (this.x += this.speed);
      gsap.set(this.$refs.marquee.$el, {
        x: Math.round(this.dir * this.x),
        force3D: true,
      });
    },
    resize() {
      this.stop();
      this.setDuplicateItems();
      this.$nextTick(() => {
        this.newTotalWidth = this.setTotalWidth();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  &-wrapper {
    overflow: hidden;
  }

  flex-wrap: nowrap;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  .item {
    height: 55px;
    flex: 0 0 auto;
    white-space: nowrap;
  }

  img {
    height: 55px;
    width: auto;
    max-width: none;
  }
}
</style>
