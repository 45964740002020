<template>
  <component
    :is="tag"
    :class="['title', `typo--${size}`]"
    v-html="value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let data = {
      value: '',
      tag: 'h2',
      size: 'l',
    };
    data = { ...data, ...this.data };
    return data;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/03_typography';

.title {
  @include mq(l) {
    max-width: 60vw;
  }
}
</style>
