<template>
  <div class="cover-wrapper">
    <section
      v-if="!($route.meta.transition === 'from-home' && !home)"
      :class="['cover']"
    >
      <MediaComponent
        ref="videoCover"
        :data="
          Object.assign(
            {},
            data,
            { video: { ...data.video, loop: home ? false : true, home, cover: true } },
            { image: { ...data.image, loop: home ? false : true, home, cover: true } }
          )
        "
        :vh="true"
        :cursor="cursor"
      />
    </section>

    <section
      v-if="data.video.vimeo_full && (!home || april)"
      :key="data.video.vimeo_full.uri"
      :class="[
        'cover',
        'cover-embed',
        $route.meta.transition === 'from-home' && !home ? 'cover-embed-fx' : false,
      ]"
    >
      <Cta
        v-if="data.video.vimeo_full && !home && (isMobile || isTouch || isTouch)"
        v-show="!videoEmbed"
        class="cover-cta"
        :data="{ title: 'Play full video', size: 's', fn: () => {} }"
      />

      <div
        :class="['status']"
        @click="setSeek"
      >
        <div class="bar">
          <div
            class="progress"
            :style="{ transform: `scaleX(${progress})` }"
          />
        </div>
      </div>

      <MediaComponent
        ref="fullVideo"
        :data="
          Object.assign({}, data, {
            video: { ...data.video, teaser: null, src: null, embedMode: true, cover: true },
          })
        "
        :vh="true"
      />
      <div
        v-if="data.video.vimeo_full && (!home || april) && videoEmbed"
        class="ui-bottom"
      >
        <Spacer template="header">
          <div class="ui-bottom-wrapper">
            <div class="time">
              {{ millisToMinutesAndSeconds(current) }}/<span>{{
                millisToMinutesAndSeconds(videoEmbed.duration)
              }}</span>
            </div>
          </div>
        </Spacer>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { millisToMinutesAndSeconds } from '@/assets/js/utils';
import Cta from '@/components/typo/cta';
import MediaComponent from '@/components/media/index';

export default {
  name: 'Cover',
  components: {
    MediaComponent,
    Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    home: {
      type: Boolean,
      default: false,
    },
    april: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      current: 0,
      millisToMinutesAndSeconds,
      progressSignal: false,
    };
  },
  computed: {
    ...mapGetters([
      'archiveStatus',
      'videoEmbed',
      'isMobile',
      'isTouch',
      'homeVideo',
      'noContent',
      'isTouch',
    ]),
  },
  watch: {
    videoEmbed(val) {
      if (val && val.id === this.$refs?.fullVideo?.$refs?.media?.plyr?.id) {
        this.progressSignal = true;
        this.$aion.add(this.setProgress, 'setProgressEmbed');
      } else if (!val && this.progressSignal) {
        this.progressSignal = false;
        this.$aion.remove('setProgressEmbed');
      }
    },
  },
  methods: {
    setProgress() {
      if (this.videoEmbed) {
        this.current = this.videoEmbed.currentTime;
        this.progress = Math.min(1, this.videoEmbed.currentTime / this.videoEmbed.duration);
      }
    },
    setSeek(e) {
      if (this.videoEmbed) {
        const value = (e.clientX / window.innerWidth) * this.videoEmbed.duration;
        // this.videoEmbed.currentTime = value;
        if (value > this.videoEmbed.currentTime) {
          const seek = value - this.videoEmbed.currentTime;
          this.videoEmbed.forward(seek);
        } else {
          const seek = this.videoEmbed.currentTime - value;
          this.videoEmbed.rewind(seek);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-wrapper {
  background: $black;
  height: 100%;
}
.cover {
  height: calc(100 * var(--vh, 1vh));

  background: transparent;
  overflow: hidden;
  /*
  @include mq($until: xs) {
    height: -webkit-fill-available;
  } */

  &.cover-home {
    height: calc(100 * var(--vh, 1vh));

    .is-ios & {
      height: -webkit-fill-available;
    }

    @include mq($until: iPad) {
      height: -webkit-fill-available;
    }
  }

  .cover-cta {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 5;
    color: $white;
    pointer-events: none;
    cursor: pointer;
  }

  .cover-video-mobile {
    position: absolute;
    display: none;
  }

  &.cover-embed-fx {
    transform: translateY(-100%) translateY(var(--offset));
  }

  &.cover-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: calc(100 * var(--vh, 1vh));

    ::v-deep .media {
      transition: opacity 0.2s ease-out;
      opacity: 0;
    }

    .ui-bottom {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 21;
      color: var(--color, $white);
      transform: translate3d(-50%, 0, 0);

      span {
        opacity: 0.7;
      }

      .time {
        align-self: center;
      }

      pointer-events: none;
    }

    .status {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 200;
      transform: translate3d(0, -120%, 0);
      transition: transform 0.2s ease-out;
      pointer-events: auto;
      transform-origin: 50% 0%;
    }

    .bar {
      position: relative;
      height: $unit * 0.4;
      background: rgba($white, 0.3);
    }

    .progress {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: 0% 50%;
      background: var(--color, $white);
    }

    &-visible {
      .status {
        transform: translate3d(0, 0%, 0);

        &:hover {
          transform: translate3d(0, 0%, 0) scaleY(2);
        }
      }
      ::v-deep .media {
        opacity: 1;
      }
    }
  }
}
</style>
