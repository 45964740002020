<template>
  <button @click="fn">
    <inline-svg
      :src="require(`@/assets/svg/icons/${name}.svg`)"
    />
  </button>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    fn: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 20px;
  height: 20px;
  color: inherit;

  pointer-events: auto;
  cursor: pointer;
}
</style>
