<template>
  <Column size="12">
    <Grid
      :col="gridCol"
      :template="gridTemplate"
      :data-layout="data.style.type !== 'default' ? data.style.type : null"
      :class="[data.style.classes !== '' ? data.style.classes.split(' ') : null]"
    >
      <div
        v-for="(item, index) in data.items"
        :key="index"
        class="item"
      >
        <Content :data="content(item)" />
      </div>
    </Grid>
  </Column>
</template>

<script>
import Content from '@/components/blocks/content';

export default {
  name: 'Repeater',
  components: {
    Content,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? (12 / this.data.item.length).toString() : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          m: (12 / (this.data.item?.length || this.data.categories?.length || this.data.posts?.length)).toString() || 3,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
  methods: {
    content(item) {
      const {
        title, rich_text,
      } = item;

      return {
        title,
        tag_title: 'h2',
        size_title: 'l',
        rich_text,
      };
    },
  },
};
</script>

<style>

</style>
