<template>
  <transition
    appear
    :css="false"
    @enter="enter"
    @leave="leave"
  >
    <Spacer
      v-touch:swipe.left="close"
      bottom="xs"
      :right="{ m: 's' }"
      :use-margin="true"
    >
      <div class="notification">
        <Spacer :all="true">
          <Icon
            name="close"
            :fn="close"
            class="icon"
          />
          <template v-if="data.id === 'audio'">
            <Spacer right="m">
              <Label :data="{ value: data.text, tag: 'div', size: 'xs' }" />
            </Spacer>
            <Spacer y="xs">
              <Cta
                :data="{ title: data.label, size: 'xs', fn: setAudio }"
                class="cta t-center"
              />
            </Spacer>
          </template>
          <template v-else-if="data.id === 'newsletter'">
            <Spacer
              right="m"
              :y="success ? 'xs' : '0'"
            >
              <Label
                :data="{
                  value: !success
                    ? data.text
                    : 'You have successfully subscribed to the newsletter',
                  tag: 'div',
                  size: 'xs',
                }"
              />
            </Spacer>
            <Spacer
              v-if="!success"
              y="xs"
            >
              <div :class="['input-wrapper', 'typo--s', 'black-ext']">
                <Spacer template="chip">
                  <input
                    ref="input"
                    v-model="email"
                    type="text"
                    :placeholder="'Your e-mail'"
                  >
                </Spacer>
              </div>
              <Spacer top="s">
                <Cta
                  :data="{ title: data.label, size: 'xs', fn: sendMail }"
                  class="cta t-center"
                />
              </Spacer>
            </Spacer>
          </template>
          <template v-else>
            <Spacer right="m">
              <Label :data="{ value: data.text, tag: 'div', size: 'xs' }" />
            </Spacer>
            <Spacer y="xs">
              <Cta
                :data="{
                  title: data.link.title,
                  size: 'xs',
                  url: data.link.url,
                  target: data.link.target,
                  fn: close,
                }"
                class="cta t-center"
              />
            </Spacer>
          </template>
        </Spacer>
      </div>
    </Spacer>
  </transition>
</template>

<script>
import axios from 'axios';
import gsap from 'gsap';
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';

import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

export default {
  name: 'Notification',
  components: {
    Label,
    Cta,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      success: false,
    };
  },
  computed: {
    ...mapGetters(['transition', 'audio']),
  },
  watch: {
    audio(val) {
      if (val && this.data.id === 'audio') {
        this.close();
      }
    },
  },
  methods: {
    // acceptCookies() {
    //   Cookies.set('april-cookie', '1', { expires: 365 });
    //   this.close();
    // },
    sendMail() {
      // eslint-disable-next-line no-undef
      const { url } = __VUE_WORDPRESS__.routing;
      axios
        .get(`${url}/wp-content/themes/gds-vue/functions/mailchimp/join.php`, {
          params: {
            email: this.email,
          },
        })
        .then((res) => {
          if (res.data) {
            const { mailchimp } = res.data;
            const response = JSON.parse(mailchimp);
            if (response.status === 'subscribed') {
              this.success = true;
              Cookies.set('april-newsletter', '1', { expires: 365 });
            }
          }
        });
    },
    setAudio() {
      if (!this.audio) {
        this.$store.commit('SET_AUDIO', true);
        this.close();
      }
    },
    close() {
      if (this.data.id !== 'cookies' && this.data.id !== 'audio') {
        Cookies.set(`april-${this.data.id}`, '1', { expires: 30 });
      }
      this.$store.commit('REMOVE_NOTIFICATION', this.data.id);
    },
    enter(el, done) {
      gsap.set(el, {
        y: 30,
        autoAlpha: 0,
      });
      gsap.to(el, {
        y: 0,
        autoAlpha: 1,
        duration: 0.3,
        delay: 0.3 * (this.$parent.parseNotifications.length - this.$vnode.key),
        ease: 'power2.out',
        onComplete: () => {
          done();
        },
      });
    },
    leave(el, done) {
      gsap.to(el, {
        y: 30,
        autoAlpha: 0,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          done();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  position: relative;
  background: rgba(#251c18, 0.9);
  color: $white;
  border-radius: 10px;
  width: 280px;

  @include mq($until: m) {
    width: 320px;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border: 1px solid $black;
    background: $black;

    input {
      appearance: none;
      border: 0;
      box-shadow: none;
      font: inherit;
      width: 100%;
      background: none;

      color: $white;

      &::placeholder {
        color: rgba($white, 0.4);
      }

      width: 100%;
    }
  }

  .icon {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 14px;
    height: 14px;
    color: $grey-light;
  }

  .cta {
    color: $grey-light;
    transition: color 0.4s $ease-custom;

    @media (hover: hover) {
      &:hover {
        color: $white;
      }
    }
  }
}
</style>
