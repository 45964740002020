<template>
  <Column
    size="12"
    class="ctas"
  >
    <Grid
      :col="{ default: 6, m: 3 }"
      :extra-class="['row-gap-custom-xxs']"
    >
      <div
        v-for="(item, index) in data.ctas"
        :key="index"
        class="item"
      >
        <Cta
          :data="{ ...item.link, style: 'pill', background: item.background, color: item.color, size: isMobile ? 'l' : 'xl' }"
        />
      </div>
    </Grid>
  </Column>
</template>

<script>
import Cta from '@/components/typo/cta';
import { mapGetters } from 'vuex';

export default {
  name: 'Ctas',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
.ctas {
  @include mq(xl) {
    max-width: calc(6px + (4 * 400px))
  }
}
</style>
