<template>
  <div
    v-if="loaded"
    :class="[$route.name === 'Home' ? 'is-home' : false]"
  >
    <div
      v-show="$route.name === 'Home' && !menuStatus"
      ref="status"
      :class="['status']"
    >
      <Grid
        :key="currentSlideIndex"
        col="auto"
      >
        <div
          v-for="(content, index) in filteredContents"
          :key="`progress-${index}`"
          class="bar"
        >
          <div
            ref="progressRef"
            class="progress"
            style="transform: scaleX(0)"
          />
        </div>
      </Grid>
    </div>
    <div
      ref="slider"
      class="slider"
    >
      <section
        v-for="(nav, parentIndex) in post.acf.navigation"
        :key="nav.pillar_page"
        ref="slideRef"
        class="slide"
        :data-index="parentIndex"
      >
        <div
          v-for="(content, index) in nav.content"
          :key="index"
          :data-index="
            (content.custom && content.is_video) ||
              (!content.custom && content.project) ||
              (content.custom && content.layout_custom === 'slide') ||
              ((isMobile || isTouch) && content.layout_custom !== 'logo')
              ? index
              : null
          "
          class="pillar"
        >
          <template v-if="content.custom">
            <!-- April -->
            <template v-if="content.is_reel && content.is_video">
              <div
                ref="itemRef"
                class="item item-slide"
                data-page="april"
                data-layout="video"
              >
                <div
                  v-if="!isMobile && !isTouch"
                  :class="[
                    'title-wrapper',
                    $route.name !== 'Home' ? 'title-wrapper-hide' : false,
                    $route.meta.archive || menuStatus ? 'title-wrapper-hide' : false,
                  ]"
                >
                  <div class="title">
                    <Grid :col="{ default: 12, m: 6 }">
                      <div />
                      <Title
                        v-if="content.title !== ''"
                        :data="{
                          value: content.title,
                          size: 'm',
                          tag: 'h2',
                        }"
                        class="title title-fix"
                      />
                    </Grid>
                  </div>
                </div>
                <Cover
                  ref="reelCover"
                  :data="content.media"
                  :home="true"
                  :april="true"
                  :cursor="content.label_cta ? content.label_cta : 'Explore'"
                />
              </div>
            </template>
            <!-- Pillar -->
            <template v-else-if="content.is_video">
              <div
                ref="itemRef"
                class="item item-slide item-slide-video"
                :data-layout="content.media.type"
                :data-page="nav.pillar_taxonomy.slug"
              >
                <template v-if="content.layout_custom === 'slide'">
                  <div class="item-slide-new-wrapper">
                    <router-link :to="content.link ? content.link.url : nav.pillar_page">
                      <div class="item-slide-new-text">
                        <Flex :align="'v-center'">
                          <Spacer
                            x="s"
                            top="s"
                            :bottom="{ default: 's', m: 'm' }"
                          >
                            <Richtext
                              :data="{
                                value: content.richtext,
                                size: isMobile ? 'm' : 'l',
                                expanded: content.text_wrapper === 'full',
                              }"
                            />
                          </Spacer>
                        </Flex>
                      </div>
                      <Cover
                        :data="content.media"
                        :home="true"
                        :cursor="content.label_cta ? content.label_cta : 'Explore'"
                      />
                    </router-link>
                  </div>
                </template>
                <template v-else>
                  <router-link :to="nav.pillar_page">
                    <div
                      v-if="!isMobile && !isTouch"
                      :class="[
                        'title-wrapper',
                        $route.name !== 'Home' ? 'title-wrapper-hide' : false,
                        $route.meta.archive || menuStatus ? 'title-wrapper-hide' : false,
                      ]"
                    >
                      <div class="title">
                        <Grid :col="{ default: 12, m: 6 }">
                          <div />
                          <Title
                            v-if="content.title !== ''"
                            :data="{
                              value: content.title,
                              size: 'm',
                              tag: 'h2',
                            }"
                            class="title title-fix"
                          />
                        </Grid>
                      </div>
                    </div>
                    <Cover
                      :data="content.media"
                      :home="true"
                      :cursor="content.label_cta ? content.label_cta : 'Explore'"
                    />
                  </router-link>
                </template>
              </div>
            </template>
            <template v-else-if="!content.is_video && content.layout_custom === 'slide'">
              <div
                ref="itemRef"
                class="item-slide item-slide-new"
                data-layout="text-slide"
                :data-page="nav.pillar_taxonomy.slug"
                :style="{
                  '--background': content.background_color,
                  '--color': content.text_color,
                }"
              >
                <router-link
                  :to="content.link ? content.link.url : nav.pillar_page"
                  :data-cursor="content.label_cta ? content.label_cta : 'Explore'"
                >
                  <div class="item-slide-new-text">
                    <Flex :align="'center'">
                      <Spacer
                        x="s"
                        top="s"
                        :bottom="{ default: 's', m: 'm' }"
                      >
                        <Richtext
                          :data="{
                            value: content.richtext,
                            size: 'xxl',
                            expanded: content.text_wrapper === 'full',
                          }"
                        />
                      </Spacer>
                    </Flex>
                  </div>
                </router-link>
              </div>
            </template>
            <template v-else-if="!content.is_video && content.layout_custom !== 'logo'">
              <div
                :ref="isMobile || isTouch ? 'itemRef' : null"
                data-layout="text"
                class="item-text item-slide t-center"
                :data-page="nav.pillar_taxonomy.slug"
                :style="{
                  '--background': content.background_color,
                  '--color': content.text_color,
                }"
              >
                <component
                  :is="
                    content.link && content.link.url && content.link.url.startsWith('http')
                      ? 'a'
                      : 'router-link'
                  "
                  :to="
                    content.link && content.link.url && !content.link.url.startsWith('http')
                      ? content.link.url
                      : undefined
                  "
                  :href="
                    content.link && content.link.url && content.link.url.startsWith('http')
                      ? content.link.url
                      : undefined
                  "
                  :target="
                    content.link && content.link.url && content.link.url.startsWith('http')
                      ? '_blank'
                      : undefined
                  "
                >
                  <Flex align="center">
                    <Spacer
                      v-if="!isMobile"
                      top="s"
                      :bottom="{ default: 's', m: 'm' }"
                      class="marquee-text-wrapper"
                    >
                      <marquee-text :repeat="5">
                        <Richtext :data="{ value: content.richtext }" />
                      </marquee-text>
                    </Spacer>

                    <Spacer
                      v-else
                      x="s"
                      top="s"
                      :bottom="{ default: 's', m: 'm' }"
                    >
                      <Richtext :data="{ value: content.richtext }" />
                    </Spacer>
                  </Flex>
                </component>
              </div>
            </template>

            <template
              v-else-if="
                !content.is_video && content.layout_custom === 'logo' && !isMobile && !isTouch
              "
            >
              <router-link
                to="/projects"
                class="item-text item-slide item-logo t-center"
                data-layout="logo"
              >
                <Spacer y="l">
                  <Marquee
                    :items="content.logo_clients"
                    :index="index"
                    :direction="index % 2 === 0 ? 'left' : 'right'"
                  />
                </Spacer>
              </router-link>
            </template>
          </template>
          <!-- Project -->
          <template v-else-if="content.project">
            <div
              ref="itemRef"
              class="item item-slide"
              :data-page="projects[content.project.ID].slug"
              :data-layout="content.media_project || 'video'"
            >
              <router-link :to="projects[content.project.ID].link">
                <div
                  v-if="!isMobile && !isTouch"
                  :class="[
                    'title-wrapper',
                    $route.name !== 'Home' ? 'title-wrapper-hide' : false,
                    $route.meta.archive || menuStatus ? 'title-wrapper-hide' : false,
                  ]"
                >
                  <div class="title">
                    <Grid :col="{ default: 12, m: 6 }">
                      <div />
                      <Title
                        :data="{
                          value: projects[content.project.ID].title.rendered,
                          size: 'm',
                          tag: 'h2',
                        }"
                        class="title title-fix"
                      />
                    </Grid>
                  </div>
                </div>
                <Cover
                  v-if="content.media_project === 'image'"
                  :data="{
                    ...projects[content.project.ID].acf.cover,
                    type: 'image',
                  }"
                  :home="true"
                  :cursor="content.label_cta ? content.label_cta : 'Explore'"
                />
                <Cover
                  v-if="projects[content.project.ID].acf.cover"
                  :data="projects[content.project.ID].acf.cover"
                  :home="true"
                  :cursor="content.label_cta ? content.label_cta : 'Explore'"
                />
              </router-link>
            </div>
          </template>
        </div>
      </section>

      <section
        :class="['slide', 'slide-clone', 'slide-clone-first']"
        data-index="0"
      >
        <div
          ref="slideCloneFirst"
          class="pillar"
          data-index="0"
        />
      </section>

      <section
        :class="['slide', 'slide-clone', 'slide-clone-last']"
        data-index="-1"
      >
        <div
          ref="slideCloneLast"
          data-index="-1"
          class="pillar"
        />
      </section>
    </div>
    <div
      v-if="(isMobile || isTouch) && $route.name === 'Home'"
      v-touch:swipe.right="() => setCurrentByNav(false)"
      v-touch:swipe.left="() => setCurrentByNav(true)"
      v-touch:swipe.top="goTo"
      :class="['navigation', videoEmbed ? 'navigation-hide' : false]"
    >
      <div
        class="btn prev"
        @click="() => setCurrentByNav(false)"
      />
      <div
        class="btn next"
        @click="() => setCurrentByNav(true)"
      />
      <div
        class="cta"
        @click="goTo"
      >
        <Spacer bottom="s">
          <Title
            :key="ctaMobile"
            :data="{
              value: ctaMobile,
              size: 's',
              tag: 'h3',
            }"
            class="title title-fix"
          />
        </Spacer>
        <Icon name="cta" />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import { isSafari, isIOS } from '@/assets/js/utils';

import medusa from '@/assets/js/observer';

import Cover from '@/components/custom/cover';
import Title from '@/components/typo/title';
import Icon from '@/components/ui/icon';
import Richtext from '@/components/typo/richtext';
import Marquee from '@/components/custom/marquee';

export default {
  name: 'Home',
  components: {
    Title,
    Cover,
    Icon,
    Richtext,
    Marquee,
  },
  data() {
    return {
      post: null,
      projects: {},
      loaded: false,
      currentVideoIndex: 0,
      currentPillarIndex: 0,
      currentSlideIndex: 0,
      currentItemIndex: 0,
      currentItem: null,
      firstTime: true,
      prevItemIndex: -1,
      counterLogo: 0,
      force: false,
      currentSlug: 'april',
      fromSlug: null,
      layout: undefined,
      checkTitleSignal: false,
      progressItemText: {
        signal: false,
        interval: 0,
        maxDuration: 8,
      },
      ctaMobile: '',
      currentVideo: null,
      titleFadeSignal: false,
      titles: [],
      homeVideosPromises: [],
      prevHomeVideo: null,
      progressPromises: 0,
      progressPromisesLength: 0,
      timeout: null,
      scrolling: false,
    };
  },
  computed: {
    ...mapGetters([
      'homePillarIndexNav',
      'homeVideo',
      'homeVideos',
      'ui',
      'loader',
      'videoEmbed',
      'isMobile',
      'isTouch',
      'currentColor',
      'noContent',
      'audio',
      'forceFromMenu',
      'menuStatus',
    ]),
    filteredContents() {
      if (this.isMobile || this.isTouch) {
        return this.post.acf.navigation[this.currentSlideIndex].content.filter(
          (content) => (!content.custom && content.project)
            || (content.custom && content.layout_custom !== 'logo'),
        );
      }

      return this.post.acf.navigation[this.currentSlideIndex].content.filter(
        (content) => (content.custom && content.is_video)
          || (!content.custom && content.project)
          || (content.custom && content.layout_custom === 'slide'),
      );

      // return this.post.acf.navigation[this.currentSlideIndex].content;
    },
    lastContent() {
      const nav = this.post.acf.navigation[this.post.acf.navigation.length - 1];
      return nav.content[nav.content.length - 1];
    },
  },
  watch: {
    progressPromises(val) {
      if (val && this.progressPromisesLength > 0) {
        const loader = document.querySelector('#loader');
        const loaderFill = document.querySelector('#loader .fill');
        const delta = (val / this.progressPromisesLength) * 100;
        gsap.killTweensOf(loaderFill);
        gsap.to(loaderFill, {
          clipPath: `inset(0% 0% 0% ${delta}%)`,
          webkitClipPath: `inset(0% 0% 0% ${delta}%)`,
          ease: 'power2.out',
          duration: 0.2,
          onComplete: () => {
            if (delta === 100) {
              loader.style.display = 'none';
              this.$store.commit('SET_LOADER', false);
              // gsap.to(loader, {
              //   autoAlpha: 0,
              //   duration: 0.5,
              //   ease: 'power2.out',
              //   onComplete: () => {
              //     this.$store.commit('SET_LOADER', false);
              //   },
              // });
            }
          },
        });
      }
    },
    ui(val) {
      if (val.status === 'video') {
        if (this.$refs.itemRef[this.currentItemIndex]) {
          const title = this.$refs.itemRef[this.currentItemIndex].querySelector('.title-wrapper');
          const els = [this.$refs.status, title];

          gsap.killTweensOf(els);
          gsap.to(els, {
            autoAlpha: val.show ? 1 : 0,
            clearProps: val.show ? 'autoAlpha' : '',
            ease: 'power2.out',
            duration: 0.4,
          });
        }
      }
    },
    loader(val) {
      if (!val) {
        this.$store.commit('SET_HOME_READY');
        this.$store.commit('SET_TRANSITION_STATUS', false);

        // this.init();
        // this.fixPillarIndex();
        // this.check();
      }
    },
    $route(to, from) {
      if (from.name) {
        if (to.name === 'Home') {
          if (this.$el) {
            gsap.set(this.$el.parentNode, {
              clearProps: 'all',
            });
          }
        }

        this.$nextTick(this.check);
      }

      if (to.name === 'Home') {
        this.$store.commit('SET_CURRENT_COLOR', ['#ffffff']);
        document.documentElement.classList.add('safari-fix');
        window.scroll(0, 0);

        if (this.isMobile) {
          window.scroll(0, 0);
          this.$nextTick(() => {
            disableBodyScroll(document.querySelector('.disable'));
          });
        }
      } else {
        document.documentElement.classList.remove('safari-fix');
        if (this.isMobile) {
          clearAllBodyScrollLocks();
        }
      }
      if (this.homeVideo && this.homeVideo.playing) this.homeVideo.loop = to.name !== 'Home';
    },
    homePillarIndexNav(val) {
      if (val >= 0) {
        this.force = true;
        if (this.$refs.slideRef[val]) {
          const target = this.isMobile || this.isTouch
            ? this.$refs.slideRef[val].querySelector('.item-slide:not(.item-logo)')
            : this.$refs.slideRef[val].querySelector(
              '.item-slide:not(.item-logo):not(.item-text)',
            );
          this.setIndexes(target);

          this.$nextTick(() => {
            this.scrollToNext();
            this.force = false;
          });
        }
      }
    },
    currentItemIndex() {
      this.progressItemText.signal = false;
      this.progressItemText.interval = 0;

      this.$nextTick(() => {
        this.$refs.progressRef.forEach((progress, index) => {
          if (index !== this.currentPillarIndex) {
            if (index < this.currentPillarIndex) {
              gsap.killTweensOf(progress);
              gsap.set(progress, {
                scaleX: 1,
              });
            }

            if (index > this.currentPillarIndex) {
              gsap.killTweensOf(progress);
              gsap.set(progress, {
                scaleX: 0,
              });
            }
          }
        });

        if (this.timeout) window.clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => {
            this.homeVideos.forEach((video, index) => {
              if (this.layout === 'video') {
                if (index !== this.currentVideoIndex) {
                  video.currentTime = 0;
                  if (video.playing && this.homeVideosPromises[index]) video.pause();
                } else if (video.paused) {
                  const { audio } = this;
                  if (audio) {
                    this.$store.commit('SET_AUDIO', false);

                    this.$nextTick(() => {
                      this.$store.commit('SET_AUDIO', true);
                    });
                  }

                  video.play().then(() => {
                    this.homeVideosPromises[index] = true;
                  });
                }
              }
            });
          },
          this.scrolling ? 1 : 501,
        );
      });

      if (this.isMobile || this.isTouch) {
        this.ctaMobile = this.post.acf.navigation[this.currentSlideIndex].content[
          this.currentPillarIndex
        ].custom
          ? !this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
            .is_video
            ? this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex].link
              .title
            : this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
              .title
          : this.projects[
            this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
              .project.ID
          ].title.rendered;
        this.scrollToNext();
      }
    },
  },
  created() {
    this.post = this.$store.state.wp.pages.index;

    this.loadPost().then(() => {
      this.loaded = true;

      this.$nextTick(() => {
        this.init();
        this.fixPillarIndex();
        this.check();

        this.homeVideos.map((el, index) => {
          if (el.id) {
            el.elements.wrapper.dataset.index = index;
          } else {
            el.dataset.index = index;
          }
          return true;
        });

        this.$refs.itemRef.forEach((item, index) => {
          if (index === 0) {
            this.layout = item.dataset.layout;
          }
          item.dataset.index = index;
        });
      });
    });

    if (this.$route.name === 'Home') this.$store.commit('SET_CURRENT_COLOR', ['#ffffff']);

    if (this.isMobile || this.isTouch) {
      this.post.acf.navigation.forEach((navigation) => {
        navigation.content = navigation.content.filter(
          (content) => (!content.custom && content.project)
            || (content.custom && content.layout_custom !== 'logo'),
        );
      });
    }
  },
  mounted() {
    // this.$aion.add(this.checkTitle, 'checkTitle', true);
    if (this.$route.name === 'Home') {
      document.documentElement.classList.add('safari-fix');
      if (this.isMobile) {
        disableBodyScroll(document.querySelector('.disable'));
      }
    }
    this.$bus.$on('windowResized', this.resize);
  },
  methods: {
    async loadPost() {
      const promises = [];
      this.post.acf.navigation.forEach((nav) => {
        nav.content.map((content) => {
          if (content.project) {
            const request = {
              type: `${content.project.post_type}s`,
              slug: content.project.post_name,
            };
            promises.push(this.$store.dispatch('getSingleBySlug', request));
          }

          return true;
        });
      });

      this.progressPromisesLength = promises.length;
      promises.forEach((promise) => promise.then(() => {
        this.progressPromises += 1;
      }));

      const posts = await Promise.all(promises);
      posts.forEach((post) => {
        if (post?.id) {
          this.projects[post.id] = post;
        }
      });
    },
    resize() {
      if (!this.isMobile && !this.isTouch && this.$route.name !== 'Home') {
        this.$nextTick(() => {
          const homeItemY = this.$refs.itemRef[this.currentItemIndex].getBoundingClientRect().y;
          gsap.set(this.$el.parentNode, {
            y: gsap.getProperty(this.$el.parentNode, 'y') + homeItemY * -1,
          });
        });
      }

      if (this.isMobile || this.isTouch) {
        this.$nextTick(() => {
          gsap.set(this.$refs.slider, {
            x: this.currentItemIndex * window.innerWidth * -1,
          });
        });
      }
    },
    check() {
      this.currentSlug = this.$route.name === 'Home'
        ? 'april'
        : this.$route.params.project
          ? this.$route.params.project
          : this.$route.params.pillar;
      this.signalSetCurrentIndex = true;

      if (this.currentSlug === 'april') {
        this.$aion.add(this.setCurrentProgress, 'setCurrentProgress');

        if (this.$route.from) {
          this.fromSlug = !this.noContent.includes(this.$route.from.params.pillar)
            ? this.$route.from.params.project
            : this.$route.from.params.pillar;
        }
        if (this.homeVideos[this.currentVideoIndex].id) this.homeVideos[this.currentVideoIndex].play();
      } else {
        this.$aion.remove('setCurrentProgress');
        this.force = true;

        this.$refs.itemRef.map((item) => {
          if (item.dataset.page === this.currentSlug) {
            this.$nextTick(() => {
              this.setIndexes(item);
            });
          }
          return true;
        });

        if (!this.isMobile && !this.isTouch) {
          this.titles.forEach((title) => {
            title.classList.remove('title-hide');
          });
        }

        // fixed
        if (!(this.$store.state.route.from && this.$store.state.route.from.name === 'Home')) {
          gsap.set(this.$el.parentNode, {
            position: 'fixed',
          });

          if (!this.isMobile && !this.isTouch) {
            this.$nextTick(() => {
              const homeItemY = this.$refs.itemRef[this.currentItemIndex].getBoundingClientRect().y;
              gsap.set(this.$el.parentNode, {
                y: gsap.getProperty(this.$el.parentNode, 'y') + homeItemY * -1,
              });
            });
          } else {
            this.$nextTick(() => {
              gsap.set(this.$refs.slider, {
                x: this.currentItemIndex * window.innerWidth * -1,
              });
            });
          }
        }
      }
    },
    init() {
      if (this.$refs.slideCloneFirst && this.$refs.itemRef.length) {
        this.$refs.slideCloneFirst.appendChild(this.$refs.itemRef[0].cloneNode(true));
      }
      if (this.$refs.slideCloneLast) {
        this.$refs.slideCloneLast.appendChild(
          this.$refs.itemRef[this.$refs.itemRef.length - 1].cloneNode(true),
        );
      }

      if (this.isMobile || this.isTouch) {
        this.ctaMobile = this.post.acf.navigation[this.currentSlideIndex].content[
          this.currentPillarIndex
        ].custom
          ? !this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
            .is_video
            ? this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex].link
              .title
            : this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
              .title
          : this.projects[
            this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex]
              .project.ID
          ].title.rendered;
      }

      const title = this.$refs.itemRef[this.currentItemIndex].querySelector('.title-wrapper .title');
      const els = [this.$refs.status, title];
      gsap.set(els, {
        autoAlpha: 0,
      });

      gsap.to(els, {
        autoAlpha: 1,
        ease: 'power2.out',
        duration: 1.2,
        delay: 0.5,
      });

      medusa.ref.addTarget({
        id: 'homeItems',
        container: document.body,
        threshold: 0.71,
        nodes: this.$refs.itemRef,
        mode: 'default',
        callback: this.callBackObserver,
        autoremove: false,
      });

      this.homeVideos.map((video) => {
        if (video.id) {
          video.on('ended', () => {
            if (this.$route.name !== 'Home') return;
            if (this.menuStatus) return;
            this.nextTarget();
            this.$nextTick(this.scrollToNext);
          });
        }

        return true;
      });

      gsap.set(this.$refs.progressRef, {
        scaleX: 0,
      });

      if (!this.isMobile && !this.isTouch) {
        this.titles = Array.from(this.$el.querySelector('.title-wrapper .title'));
      }
    },
    callBackObserver(entry) {
      if (this.forceFromMenu) return;
      if (this.$route.name !== 'Home') return;
      if (!this.isMobile && !this.isTouch) {
        this.scrolling = true;
        const { target, isIntersecting } = entry;
        if (isIntersecting) {
          this.setIndexes(target);
        }
      }
    },
    setIndexes(target) {
      this.prevItemIndex = this.currentItemIndex;

      const { index } = target.dataset;
      const { realIndex: pillarIndex } = target.parentNode.dataset;
      const { index: slideIndex } = target.parentNode.parentNode.dataset;
      this.layout = target.dataset.layout;

      this.currentItemIndex = parseInt(index, 10) || 0;

      this.prevHomeVideo = this.homeVideos[this.currentVideoIndex];

      if (this.layout === 'video') {
        const { index: videoIndex } = target.querySelector('.plyr__video-wrapper').dataset;
        this.currentVideoIndex = parseInt(videoIndex, 10);
      }
      if (this.layout === 'image') {
        const { index: videoIndex } = target.querySelector('figure.picture').dataset;
        this.currentVideoIndex = parseInt(videoIndex, 10);
      }
      this.currentPillarIndex = parseInt(pillarIndex, 10);
      this.currentSlideIndex = parseInt(slideIndex, 10);

      this.currentItem = target;

      this.updateState();
    },
    fixPillarIndex() {
      const pillars = Array.from(this.$el.querySelectorAll('.pillar[data-index]'));
      let newIndex = -1;
      let slide = 0;
      pillars.forEach((pillar) => {
        if (parseInt(pillar.parentNode.dataset.index, 10) === slide) {
          newIndex += 1;
        } else {
          slide += 1;
          newIndex = 0;
        }

        pillar.dataset.realIndex = newIndex;
      });
    },
    nextTarget() {
      const target = this.$refs.itemRef[this.currentItemIndex + 1]
        ? this.$refs.itemRef[this.currentItemIndex + 1]
        : this.$refs.itemRef[0];
      this.setIndexes(target);
    },
    prevTarget() {
      const target = this.$refs.itemRef[this.currentItemIndex - 1]
        ? this.$refs.itemRef[this.currentItemIndex - 1]
        : this.$refs.itemRef[this.$refs.itemRef.length - 1];
      this.setIndexes(target);
    },
    setCurrentByNav(dir) {
      if (this.videoEmbed) return;
      if (dir) {
        this.nextTarget();
      } else {
        this.prevTarget();
      }
    },
    updateState() {
      this.$store.commit('SET_HOME_ITEM_INDEX', this.currentItemIndex);
      this.$store.commit('SET_HOME_SLIDE_INDEX', this.currentSlideIndex);
      this.$store.commit('SET_HOME_PILLAR_INDEX', this.currentPillarIndex);

      if (this.layout === 'video' || this.layout === 'image') {
        this.$store.commit('SET_HOME_VIDEO_INDEX', this.currentVideoIndex);
        this.$store.commit('SET_HOME_VIDEO', this.homeVideos[this.currentVideoIndex]);
      }
      this.$store.commit('SET_HOME_ITEM', this.currentItem);
    },
    scrollToNext() {
      this.scrolling = false;
      if (!this.isMobile && !this.isTouch) {
        if (this.currentItemIndex === 0 && this.prevItemIndex === this.$refs.itemRef.length - 1) {
          gsap.to(window, {
            scrollTo: {
              y: 'max',
            },
            duration: 0.5,
            ease: 'power2.out',
            onComplete: () => {
              this.force = false;
              window.scroll(0, 0);
            },
          });
        } else if (
          this.currentItemIndex === this.$refs.itemRef.length - 1
          && this.prevItemIndex === 0
        ) {
          gsap.to(this.$refs.slider, {
            y: window.innerHeight + window.pageYOffset,
            duration: 0.5,
            ease: 'power2.out',
            clearProps: 'all',
            onComplete: () => {
              const max = this.$refs.itemRef[this.$refs.itemRef.length - 1].getBoundingClientRect().top
                + window.pageYOffset;
              window.scroll(0, max);
            },
          });
        } else {
          gsap.to(window, {
            scrollTo: {
              y: this.$refs.itemRef[this.currentItemIndex],
            },
            duration: 0.5,
            ease: 'power2.out',
            onComplete: () => {
              this.force = false;
            },
          });
        }
      } else if (
        this.currentItemIndex === 0
        && this.prevItemIndex === this.$refs.itemRef.length - 1
      ) {
        gsap.to(this.$refs.slider, {
          x: window.innerWidth * -1 * this.$refs.itemRef.length,
          duration: 0.5,
          ease: 'power2.out',
          onComplete: () => {
            gsap.set(this.$refs.slider, {
              x: 0,
              onComplete: () => {
                this.force = false;
              },
            });
          },
        });
      } else if (
        this.currentItemIndex === this.$refs.itemRef.length - 1
        && this.prevItemIndex === 0
      ) {
        gsap.to(this.$refs.slider, {
          x: window.innerWidth,
          duration: 0.5,
          ease: 'power2.out',
          onComplete: () => {
            gsap.set(this.$refs.slider, {
              x: window.innerWidth * -1 * this.currentItemIndex,
              onComplete: () => {
                this.force = false;
              },
            });
          },
        });
      } else {
        gsap.to(this.$refs.slider, {
          x: window.innerWidth * -1 * this.currentItemIndex,
          duration: 0.5,
          ease: 'power2.out',
          onComplete: () => {
            this.force = false;
          },
        });
      }
    },
    goTo() {
      if (this.videoEmbed) return;
      if (
        this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex].is_reel
      ) {
        this.$refs.reelCover.forEach((cover) => {
          if (this.$refs.itemRef[this.currentItemIndex].contains(cover.$el)) {
            cover.$refs.fullVideo.$refs.media.toggleFullVideo();
          }
        });
      } else {
        const content = this.post.acf.navigation[this.currentSlideIndex].content[this.currentPillarIndex];
        const link = content.custom
          ? content.link.url
            ? content.link.url
            : this.post.acf.navigation[this.currentSlideIndex].pillar_page
          : this.projects[content.project.ID].link;

        if (link && !link.startsWith('http')) {
          this.$router.push(link);
        }
      }
    },
    setCurrentProgress() {
      if (this.$refs.progressRef && this.$refs.progressRef.length > 0) {
        if (this.menuStatus) return;

        if (this.$refs.progressRef[this.currentPillarIndex]) {
          if (this.layout === 'video') {
            gsap.killTweensOf(this.$refs.progressRef[this.currentPillarIndex]);
            gsap.set(this.$refs.progressRef[this.currentPillarIndex], {
              scaleX:
                this.homeVideos[this.currentVideoIndex].currentTime
                / this.homeVideos[this.currentVideoIndex].duration,
            });
          } else if (
            (this.layout === 'text' && (this.isMobile || this.isTouch))
            || this.layout === 'image'
            || this.layout === 'text-slide'
          ) {
            if (this.progressItemText.interval < this.progressItemText.maxDuration) {
              this.progressItemText.signal = true;
              this.progressItemText.interval += 1 / 60;

              if (this.homeVideo.id) this.homeVideo.pause();
              if (this.prevHomeVideo && this.prevHomeVideo.id) this.prevHomeVideo.pause();

              gsap.set(this.$refs.progressRef[this.currentPillarIndex], {
                scaleX: this.progressItemText.interval / this.progressItemText.maxDuration,
              });
            } else {
              this.progressItemText.signal = false;
              this.progressItemText.interval = 0;

              gsap.set(this.$refs.progressRef[this.currentPillarIndex], {
                scaleX: 1,
              });
              this.nextTarget();
              this.$nextTick(this.scrollToNext);
            }
          }
        }
      }

      if (
        window.pageYOffset >= this.$el.offsetHeight - window.innerHeight
        && this.loaded
        && !this.isMobile
        && !this.isTouch
      ) {
        this.homeVideos[0].currentTime = this.homeVideos[this.homeVideos.length - 1].currentTime;
        window.scroll(0, 0);
      }
    },
    checkTitle() {
      this.checkTitleSignal = window.pageYOffset <= window.innerHeight;

      // if (this.$refs.itemRef) {
      //   if (this.$route.name === 'Home') {
      //     if (this.titles && !this.isMobile) {
      //       this.titles.forEach((title) => {
      //         const { top } = title.getBoundingClientRect();
      //         if (top <= 106) {
      //           title.classList.add('title-hide');
      //         } else {
      //           title.classList.remove('title-hide');
      //         }
      //       });
      //     }
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  .is-ios & {
    display: flex;
    align-items: flex-start;
  }

  @include mq($until: iPad) {
    display: flex;
    align-items: flex-start;
  }
}

.slide {
  position: relative;

  .is-ios & {
    display: flex;
    flex: 1 0 auto;
    min-width: 100vw;

    .pillar {
      flex: 0 0 100vw;
      width: 100vw;
    }
  }

  @include mq($until: iPad) {
    display: flex;
    flex: 1 0 auto;
    min-width: 100vw;

    .pillar {
      flex: 0 0 100vw;
      width: 100vw;
    }
  }
}

.pillar {
  top: 0;
  // position: sticky;
}

.status {
  position: fixed;

  z-index: 21;
  top: map-get($spacer, s);
  left: calc((var(--slide-width) * 2) + #{map-get($spacer, l)});
  width: 100%;
  max-width: var(--slide-width-selected);

  @include mq($from: m) {
    .is-ios & {
      left: calc((var(--slide-width) * 1) + #{map-get($spacer, l)});
    }
  }

  @include mq($until: m) {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &.sticky {
    position: sticky;
  }

  ::v-deep .grid {
    column-gap: $unit * 0.4;
  }
}

.progress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  background: $white;
}

.bar {
  position: relative;
  background: rgba($white, 0.3);
  height: 2px;
}

.item {
  position: relative;
  display: block;
  height: calc(100 * var(--vh, 1vh));

  .is-ios & {
    pointer-events: none;
    height: 100vh;
    // height: -webkit-fill-available;

    .is-home & {
      height: calc(100 * var(--vh, 1vh));
    }

    * {
      pointer-events: none !important;
    }
  }

  @include mq($until: iPad) {
    pointer-events: none;
    height: 100vh;
    // height: -webkit-fill-available;

    .is-home & {
      height: calc(100 * var(--vh, 1vh));
    }

    * {
      pointer-events: none !important;
    }
  }

  .title-wrapper {
    position: absolute;
    top: 24px;
    left: 50%;
    width: 50%;
    padding-right: 136px;
    z-index: 25;
    height: calc(100% - 24px);
    pointer-events: none;

    &-hide {
      opacity: 0 !important;
    }

    &-absolute {
      top: 0;
      // right: 0;
      // height: 30px;

      &-offset {
        // bottom: var(--offset);
      }
    }
  }

  .title {
    // mix-blend-mode: difference;
    position: sticky;
    width: 100%;
    top: 24px;
    padding-bottom: $unit * 2;
    text-align: left;
    z-index: 1;
    color: var(--color, $white);
    transition: opacity 0.4s $ease-custom;

    &-hide {
      opacity: 0;
    }
  }
}

.title-fix {
  max-width: none !important;
}

.item-text {
  position: relative;
  display: block;

  .is-ios & {
    // height: calc(100 * var(--vh, 1vh));
    height: calc(100 * var(--vh, 1vh));

    pointer-events: none;

    * {
      pointer-events: none !important;
    }
  }

  @include mq($until: iPad) {
    // height: calc(100 * var(--vh, 1vh));
    height: calc(100 * var(--vh, 1vh));

    pointer-events: none;

    * {
      pointer-events: none !important;
    }
  }

  a {
    display: block;

    .is-ios & {
      & > * {
        // height: calc(100 * var(--vh, 1vh));
        height: calc(100 * var(--vh, 1vh));
      }
    }

    @include mq($until: iPad) {
      & > * {
        // height: calc(100 * var(--vh, 1vh));
        height: calc(100 * var(--vh, 1vh));
      }
    }

    color: var(--color);
    background: var(--background);

    &:hover {
      color: var(--background);
      background: var(--color);
    }
  }
}

[data-layout="logo"] {
  height: 100px;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  // mix-blend-mode: difference;

  &.reel {
    .is-ios & {
      pointer-events: none !important;
    }

    @include mq($until: iPad) {
      pointer-events: none !important;
    }
  }

  &.navigation-hide {
    visibility: hidden !important;
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  .btn {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .cta {
    position: absolute;
    text-align: center;
    width: 100%;
    height: auto;
    left: 0;
    bottom: 24px;
    z-index: 2;
    color: $white;
  }
}

.cover-video-mobile {
  position: absolute;
  display: none;
}

.slide-clone {
  pointer-events: none;
}

.slide-clone-last {
  width: 100%;
  top: -100vh;
  left: 0;

  .is-ios & {
    position: absolute;
    top: auto;
    left: -100vw;
  }

  @include mq($until: iPad) {
    position: absolute;
    top: auto;
    left: -100vw;
  }
}

.marquee-text-wrapper {
  overflow: hidden;
  max-width: 100vw;

  ::v-deep {
    .wysiwyg {
      max-width: none;
    }
  }
}

.item-slide-new {
  position: relative;
  display: block;
  height: calc(100 * var(--vh, 1vh));

  color: var(--color);
  background: var(--background);

  & > * {
    height: 100%;
  }
}

.item-slide-new-wrapper {
  color: $white;
  position: relative;
}

.item-slide {
  a {
    display: block;
    height: 100%;
  }

  .item-slide-new-text {
    pointer-events: none;
  }
}

.item-slide-new-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;

  & > * {
    height: 100%;
    width: 100%;

    & > * {
      width: 100%;
    }
  }
}
</style>
