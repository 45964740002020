import difference from 'lodash.difference';
import orderBy from 'lodash.orderby';

import {
  fetchItems,
  fetchSingle,
} from '@/api';

export default {
  async getSingleBySlug({ getters, commit }, {
    type, slug, showLoading = false,
  }) {
    let response = null;
    const responseFromState = getters.singleBySlug({ type, slug });
    if (!responseFromState) {
      if (showLoading) {
        commit('SET_LOADING', true);
      }
      await fetchSingle({ type, params: { slug } }).then(({ data: [item] }) => {
        commit('ADD_ITEM', { type, item });
        if (showLoading) {
          commit('SET_LOADING', false);
        }
        response = item;
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getItems({ getters, commit }, {
    type, params,
  }) {
    let response = null;
    const responseFromState = getters.request({ type, params });
    const responseWithParams = responseFromState && params.slug && responseFromState.length !== params.slug.length;

    if (!responseFromState || responseWithParams) {
      let itemsFromStore = [];
      let prevParamsSlug = null;
      if (responseWithParams) {
        prevParamsSlug = params.slug;
        const slugFromStore = difference(params.slug, responseFromState);
        Object.assign(params, { slug: slugFromStore });
        itemsFromStore = responseFromState.map((slug) => getters.singleBySlug({ type, slug }));
      }
      await fetchItems({ type, params })
        .then(({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
          if (typeof (params.search) === 'undefined') {
            items.forEach((item) => commit('ADD_ITEM', { type, item }));
            commit('ADD_REQUEST', {
              type,
              request: {
                type,
                params,
                total: parseInt(total, 10),
                totalPages: parseInt(totalPages, 10),
                data: items.map((i) => i.slug),
              },
            });
          }

          const sortResponse = responseWithParams ? orderBy([...items, ...itemsFromStore],
            [(i) => prevParamsSlug.findIndex((e) => e === i.slug)]) : items;

          const parseResponse = params.per_page ? sortResponse.slice(0, params.per_page) : sortResponse;

          response = parseResponse;
        });
    } else {
      const parseResponseFromState = params.per_page ? responseFromState.slice(0, params.per_page) : responseFromState;
      response = parseResponseFromState.map((slug) => getters.singleBySlug({ type, slug }));
    }

    return response;
  },
};
