<template>
  <div
    :class="[
      'wrapper',
      ...wrapperSize,
    ]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Wrapper',
  props: {
    margin: {
      type: [Boolean],
      default: false,
    },
    size: {
      type: [Object, String, Boolean],
      default: false,
    },
  },
  computed: {
    wrapperSize() {
      const { size } = this;
      const sizes = [];
      if (typeof size === 'string' && size !== '') {
        sizes.push(`wrapper--${size}`);
      } else if (typeof size === 'object') {
        Object.keys(size).forEach((key) => {
          if (key !== 'default') {
            sizes.push(`wrapper-${key}--${size[key]}`);
          } else {
            sizes.push(`wrapper--${size[key]}`);
          }
        });
      }

      return sizes;
    },
  },
};
</script>

<style>

</style>
