<template>
  <nav class="page-aside">
    <Spacer
      ref="scroller"
      y="l"
      :x="{ default: 's', m: 'l' }"
      class="list"
    >
      <ul>
        <li
          v-for="(item, index) in data"
          :key="index"
          :data-aside="slugify(item)"
        >
          <Cta
            :data="{ title: item, fn: () => goTo(item), size: isMobile ? 'm' : 'l' }"
            :active="`#${slugify(item)}` === $route.hash"
          />
        </li>
      </ul>
    </Spacer>
  </nav>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import { slugify } from '@/assets/js/utils';
import Cta from '@/components/typo/cta';
import { mapGetters } from 'vuex';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'PageAside',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sections: [],
      force: false,
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    this.sections = document.querySelectorAll('.main-asided section[id]');
    this.$aion.add(this.checkAside, 'checkAside', true);

    if (this.$route.hash) {
      this.force = true;
      this.$nextTick(() => {
        this.setScroll(this.$route.hash);
      });
    }

    if (this.isMobile) {
      this.$store.commit('SET_ASIDE_MOBILE', true);
    }
  },
  beforeDestroy() {
    this.$aion.remove('checkAside');

    this.$router.replace({
      hash: undefined,
    });

    this.$store.commit('SET_ASIDE_MOBILE', false);
  },
  methods: {
    slugify,
    goTo(title) {
      const hash = slugify(title);
      if (this.$route.hash !== `#${hash}`) {
        this.force = true;
        this.$router.replace({
          hash,
        });
        this.setScroll(`#${hash}`);
      }
    },
    setScroll(id) {
      const section = document.querySelector(`.main-asided section${id}`);
      let offset = 0;
      if (section.parentNode.children[0].id === section.id) offset = 150;
      if (this.isMobile) offset = 138;
      const y = section.offsetTop;

      if (this.isMobile) {
        const item = this.$refs.scroller.$el.querySelector(`[data-aside="${section.id}"]`);
        const left = item.offsetLeft - 10;
        this.$refs.scroller.$el.scroll({
          top: 0,
          left,
          behavior: 'smooth',
        });
      }

      gsap.to(window, {
        duration: 0.3,
        scrollTo: {
          y: Math.max(0, y - offset),
        },
        onComplete: () => { this.$nextTick(() => { this.force = false; }); },
        onInterrupt: () => { this.$nextTick(() => { this.force = false; }); },
      });
    },
    checkAside() {
      if (this.force) return;
      let id = null;
      let scroll = 0;
      const offset = this.isMobile ? 138 : 0;
      this.sections.forEach(((section) => {
        const { top } = section.getBoundingClientRect();
        if (Math.round(top) <= offset + 1) {
          id = section.id;
          const item = this.$refs.scroller.$el.querySelector(`[data-aside="${id}"]`);
          const left = item.offsetLeft - 10;
          scroll = left;
        }
      }));

      if (id && this.$route.hash !== `#${id}`) {
        this.$router.replace({
          hash: id,
        });

        if (this.isMobile) {
          this.$refs.scroller.$el.scroll({
            top: 0,
            left: scroll,
            behavior: 'smooth',
          });
        }
      } else if (!id && this.$route.hash) {
        // this.$router.replace({
        //   hash: undefined,
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-aside {
  align-self: start;
  text-align: left;

  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: var(--background, $black);
  top: 68px;

  @include mq(m) {
    top: 105px;
  }

  ul {
    list-style: none;
  }

  @include mq($until: m) {
    overflow: hidden;

    .list {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        display: flex;
      }

      li+li {
        margin-left: map-get($spacer, m);
      }
    }
  }
}
</style>
