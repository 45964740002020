var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Column',{attrs:{"size":_vm.sectionLayoutTemplate,"offset":_vm.data.section_layout === 'content-offset-6' ? { m: 6 } : false}},[(_vm.data.label && _vm.data.label !== '')?_c('Spacer',{attrs:{"bottom":"l"}},[_c('Label',{attrs:{"data":{
        value: _vm.data.label,
        tag: _vm.data.tag_label,
        size: _vm.data.size_label
      }}})],1):_vm._e(),(_vm.data.title !== '')?_c('Title',{attrs:{"data":{
      value: _vm.data.title,
      tag: _vm.data.tag_title,
      size: _vm.data.size_title
    }}}):_vm._e(),(_vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{ value: _vm.data.rich_text, size: _vm.isMobile ? 'm' : 'l', expanded: _vm.data.expanded }}}):_vm._e(),(_vm.data.credits)?_c('Credits',{attrs:{"data":_vm.data.credits}}):_vm._e(),(_vm.data.cta && _vm.data.cta !== '')?_c('Cta',{attrs:{"data":Object.assign({}, _vm.data.cta, {style: 'btn'})}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }