/* eslint no-underscore-dangle: 0 */

import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import FontFaceObserver from 'fontfaceobserver';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';

import MarqueeText from 'vue-marquee-text-component';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/raf';
import '@/assets/js/eventBus';
import '@/assets/js/layout';

import '@/assets/js/flickity-fix';

sync(store, router);
Vue.config.productionTip = false;

Vue.use(Vue2TouchEvents, {
  swipeTolerance: 80,
});
Vue.use(InlineSvgPlugin);
Vue.component('MarqueeText', MarqueeText);

const font = new FontFaceObserver('Soehne', {
  weight: 400,
});

if (window.location.port === '8888') {
  window.location.href = window.location.href.replace('8888', '3000');
}

// eslint-disable-next-line no-undef
const { options } = __VUE_WORDPRESS__.state;

if (options && options.extra && options.extra.gtm_id && process.env.NODE_ENV !== 'development') {
  Vue.use(
    VueGtag,
    {
      config: {
        id: options.extra.gtm_id,
        params: {
          anonymize_ip: true,
        },
      },
      disableScriptLoad: true,
      bootstrap: true,
    },
    router,
  );
}

Promise.all([font.load()]).then(() => {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  router.onReady(() => app.$mount('#app'));
});
