var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wrapper',[_c('Spacer',{attrs:{"x":{ default: 's', m: 'l' }}},[_c('Grid',{attrs:{"extra-class":['fuoritempo-grid', 'column-custom-l']}},[_c('Column',{staticClass:"u-hidden--until-m",attrs:{"size":"1"}}),_c('Column',{staticClass:"fuoritempo-col-media",attrs:{"size":{ default: 12, m: 5 }}},[_c('Grid',{attrs:{"col":"12"}},[_c('Title',{staticClass:"sticky",attrs:{"data":{ value: _vm.post.title.rendered, size: 'm', tag: 'h2' }}}),_c('Spacer',{attrs:{"bottom":{ m: 's'}}},[(_vm.data.type !== 'gallery')?_c('MediaComponent',{attrs:{"data":_vm.data}}):_vm._e(),(!_vm.isMobile && _vm.data.type === 'gallery')?_vm._l((_vm.data.gallery),function(media,index){return _c('div',{key:index},[_c('Spacer',{attrs:{"top":{ default: 'xs', m: 's'}}},[_c('MediaComponent',{attrs:{"data":Object.assign({}, { type: 'image', image: { default: media } })}})],1)],1)}):(_vm.data.gallery)?[_c('Gallery',{attrs:{"data":_vm.data.gallery,"extra-class":['fuoritempo-gallery'],"extra-settings":{ autoPlay: true }}})]:_vm._e()],2)],1)],1),_c('Column',{staticClass:"fuoritempo-col-text",attrs:{"size":{ default: 12, m: 6 }}},[_c('Spacer',{staticClass:"text",attrs:{"bottom":"s"}},[(_vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{ value: _vm.data.rich_text, size: 'l' }}}):_vm._e(),_c('Spacer',{attrs:{"top":"s"}},[_c('Label',{attrs:{"data":{ value: _vm.data.credits, tag: 'h6', size: 's' }}})],1)],1)],1)],1),(_vm.$route.params.pillar === 'fuoritempo')?_c('Spacer',{attrs:{"top":"m","bottom":"l"}},[_c('Related',{attrs:{"slug":_vm.slug,"data":{
          archive_type: 'archive',
          archive: 'posts',
          archive_category: !_vm.post.acf.related || (_vm.post.acf.related && _vm.post.acf.related.length === 0) ? { taxonomy: 'pillar', term_id: 4 } : undefined,
          exclude: [_vm.post.id],
          include: _vm.post.acf.related && _vm.post.acf.related.length > 0 ? _vm.post.acf.related : undefined,
          max_posts: _vm.post.acf.related && _vm.post.acf.related.length > 0 ? _vm.post.acf.related.length : 6,
          style: {
            type: 'default',
            classes: '',
          },
          layout: [
            { media_query: 'default', layout_size: '6', layout_type: 'grid' },
            { media_query: 'm', layout_size: '2', layout_type: 'grid' } ]
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }