<template>
  <component
    :is="components[data.type]"
    ref="media"
    :data="Object.assign(data[data.type], { section_layout: data.section_layout })"
    :vh="vh || data.vh"
    :cursor="cursor"
    class="media"
  />
</template>

<script>
import Figure from '@/components/media/figure';
import VideoPlayer from '@/components/media/video';
import Gallery from '@/components/media/gallery';

export default {
  name: 'MediaComponent',
  components: {
    Figure,
    VideoPlayer,
    Gallery,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    vh: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      components: {
        image: Figure,
        video: VideoPlayer,
        gallery: Gallery,
      },
    };
  },
};
</script>

<style>

</style>
