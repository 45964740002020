var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","css":false},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('Spacer',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.close),expression:"close",arg:"swipe",modifiers:{"left":true}}],attrs:{"bottom":"xs","right":{ m: 's' },"use-margin":true}},[_c('div',{staticClass:"notification"},[_c('Spacer',{attrs:{"all":true}},[_c('Icon',{staticClass:"icon",attrs:{"name":"close","fn":_vm.close}}),(_vm.data.id === 'audio')?[_c('Spacer',{attrs:{"right":"m"}},[_c('Label',{attrs:{"data":{ value: _vm.data.text, tag: 'div', size: 'xs' }}})],1),_c('Spacer',{attrs:{"y":"xs"}},[_c('Cta',{staticClass:"cta t-center",attrs:{"data":{ title: _vm.data.label, size: 'xs', fn: _vm.setAudio }}})],1)]:(_vm.data.id === 'newsletter')?[_c('Spacer',{attrs:{"right":"m","y":_vm.success ? 'xs' : '0'}},[_c('Label',{attrs:{"data":{
                value: !_vm.success
                  ? _vm.data.text
                  : 'You have successfully subscribed to the newsletter',
                tag: 'div',
                size: 'xs',
              }}})],1),(!_vm.success)?_c('Spacer',{attrs:{"y":"xs"}},[_c('div',{class:['input-wrapper', 'typo--s', 'black-ext']},[_c('Spacer',{attrs:{"template":"chip"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"input",attrs:{"type":"text","placeholder":'Your e-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})])],1),_c('Spacer',{attrs:{"top":"s"}},[_c('Cta',{staticClass:"cta t-center",attrs:{"data":{ title: _vm.data.label, size: 'xs', fn: _vm.sendMail }}})],1)],1):_vm._e()]:[_c('Spacer',{attrs:{"right":"m"}},[_c('Label',{attrs:{"data":{ value: _vm.data.text, tag: 'div', size: 'xs' }}})],1),_c('Spacer',{attrs:{"y":"xs"}},[_c('Cta',{staticClass:"cta t-center",attrs:{"data":{
                title: _vm.data.link.title,
                size: 'xs',
                url: _vm.data.link.url,
                target: _vm.data.link.target,
                fn: _vm.close,
              }}})],1)]],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }