<template>
  <figure
    :class="['picture', vh ? 'full-height' : false]"
    :data-lazy="lazy ? 'false' : null"
    :data-cursor="cursor ? cursor : null"
  >
    <div
      v-if="imageType !== 'svg'"
      class="picture-container"
    >
      <picture :style="{ paddingBottom: !vh ? `${ratio}%` : false }">
        <source
          v-for="(image, key) in sizes"
          :key="key"
          :data-srcset="image.url"
          :srcset="!data.lazy ? image.url : null"
          :media="image.size"
        >
        <img
          ref="image"
          class="image"
          :data-src="defaultImage.subtype === 'gif' ? defaultImage.url : defaultImage.sizes.l"
          :src="
            !data.lazy
              ? defaultImage.subtype === 'gif'
                ? defaultImage.url
                : defaultImage.sizes.l
              : null
          "
          :alt="defaultImage.alt"
        >
      </picture>

      <div class="placeholder">
        <img :src="defaultImage.sizes.preload">
      </div>
    </div>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <div v-else-if="imageType === 'svg'">
      <img
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </div>

    <figcaption v-if="data.caption">
      <Spacer y="xs">
        <Label :data="{ value: data.caption, tag: 'h3', size: 's' }" />
      </Spacer>
    </figcaption>
  </figure>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Figure',
  components: {
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    vh: {
      type: Boolean,
      default: false,
    },
    ratioFromProps: {
      type: [Boolean, Number],
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    cursor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    ratio() {
      if (this.ratioFromProps) return this.ratioFromProps;
      if (!this.data && !this.defaultImage) return 0;
      return (this.defaultImage.height / this.defaultImage.width) * 100;
    },
    sizes() {
      if (!this.data && !this.defaultImage) return false;
      if (this.defaultImage.subtype === 'gif') return false;

      let mobileSizes = null;
      const { sizes } = this.defaultImage;
      if (this.data.mobile) {
        mobileSizes = this.data.mobile.sizes;
      }

      const ratio = window.devicePixelRatio > 1 ? 2 : 1;

      return {
        xxs: {
          url: mobileSizes?.xxs || sizes.xxs,
          size: `(max-width: ${sizes['xxs-width'] / ratio}px)`,
        },
        xs: {
          url: mobileSizes?.xs || sizes.xs,
          size: `(max-width: ${sizes['xs-width'] / ratio}px)`,
        },
        s: {
          url: mobileSizes?.s || sizes.s,
          size: `(max-width: ${(sizes['s-width'] * 2) / ratio}px)`,
        },
        m: {
          url: sizes.m,
          size: `(max-width: ${(sizes['m-width'] * 2) / ratio}px)`,
        },
        l: {
          url: sizes.l,
          size: `(max-width: ${(sizes['l-width'] * 2) / ratio}px)`,
        },
        xl: {
          url: sizes.xl,
          size: `(max-width: ${(sizes['xl-width'] * 2) / ratio}px)`,
        },
        xxl: {
          url: sizes.xxl,
          size: `(min-width: ${(sizes['xxl-width'] * 2) / ratio}px)`,
        },
      };
    },
  },
  mounted() {
    if (!this.lazy) {
      this.load();
    }

    if (this.data.home) {
      this.$store.commit('SET_HOME_VIDEOS', this.$el);
    }
  },
  methods: {
    async load() {
      const img = this.$refs.image;
      if (img && img.decode) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  overflow: hidden;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: 0;
    z-index: 1;
  }

  &.full-height {
    picture {
      height: calc(100 * var(--vh, 1vh));
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // transform: scale(1.02);
    transition: opacity 0.5s $ease-custom;
    will-change: opacity;
  }

  .picture-container {
    position: relative;
    overflow: hidden;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;
    background: $black;

    transition: 0.01s linear 0.5s;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      transform: scale(1.1);
      filter: blur(30px);
    }

    .is-home & {
      opacity: 0;
    }
  }

  figcaption {
    text-align: left;
  }

  &[data-lazy="false"] {
    .image {
      opacity: 0;
    }
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }

    // .image {
    //   transform: scale(1);
    // }
  }
}
</style>
