<template>
  <div
    :class="['wysiwyg', data.size ? `typo--${data.size}` : null, { expanded: data.expanded }]"
    v-html="data.value"
  />
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/src/03_typography";

.wysiwyg {
  max-width: 900px;

  &.expanded {
    max-width: 100%;
  }

  * {
    @extend %typo--m;
    font-stretch: normal;
    text-transform: none;
  }

  h6 {
    @extend %typo--s;
  }

  &.typo--l {
    * {
      @extend %typo--l;
    }

    h1 {
      @extend %typo--xxl;
    }
  }

  &.typo--xl {
    * {
      @extend %typo--xl;
    }
  }

  &.typo--xxl {
    * {
      @extend %typo--xxl;
    }
  }

  h1 {
    @extend %typo--xxl;
    margin-bottom: map-get($spacer, xs);
  }

  h2 {
    @extend %typo--xl;
    margin-bottom: map-get($spacer, xs);
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: "— ";
    list-style-position: inside;

    li {
      & > * {
        display: inline;
      }
    }
  }

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  * + ul,
  * + ol {
    margin-top: map-get($spacer, l);
  }

  h1 {
    @extend %typo--xxl;
  }

  h2 {
    @extend %typo--xl;
  }
}
</style>
