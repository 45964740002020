var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta",class:[_vm.data.style ? ("cta-" + (_vm.data.style)) : false],style:(_vm.data.style === 'pill' ? {
    '--pill-color': _vm.data.color,
    '--pill-bg-color': _vm.data.background,
  } : false)},[(_vm.data.fn && !_vm.data.url)?[_c('button',{class:{ active: _vm.active },on:{"click":_vm.data.fn}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.data.external || _vm.data.target)?[_c('a',{class:{ active: _vm.active },attrs:{"href":_vm.data.url,"target":_vm.data.target},on:{"click":function () { _vm.data.fn ? _vm.data.fn() : null }}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{class:{ active: _vm.active },attrs:{"to":_vm.data.url},nativeOn:{"click":function($event){return (function () { _vm.data.fn ? _vm.data.fn() : null }).apply(null, arguments)}}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }