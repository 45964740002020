<template>
  <section
    :id="layout.label ? slugify(layout.label) : undefined"
    :class="[
      layout.section_classes !== '' ? layout.section_classes.split(' ') : null,
      layout.section_layout === 'custom' ? layout.section_layout_custom : null,
    ]"
  >
    <slot />
  </section>
</template>

<script>
import { slugify } from '@/assets/js/utils';

export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  methods: {
    slugify,
  },
};
</script>

<style></style>
