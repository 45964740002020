<template>
  <Grid :col="{ default: 12, m: 6 }">
    <div class="credits">
      <Grid
        v-for="(item, index) in data"
        :key="index"
        col="6"
      >
        <div :class="['role', !isMobile ? 'typo--m' : 'typo--s']">
          {{ item.role }}
        </div>
        <div :class="['name', !isMobile ? 'typo--m' : 'typo--s']">
          {{ item.name }}
        </div>
      </Grid>
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Credits',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>

<style lang="scss">
</style>
