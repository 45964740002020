<template>
  <Column
    :size="sectionLayoutTemplate"
    :offset="data.section_layout === 'content-offset-6' ? { m: 6 } : false"
  >
    <Spacer
      v-if="data.label && data.label !== ''"
      bottom="l"
    >
      <Label
        :data="{
          value: data.label,
          tag: data.tag_label,
          size: data.size_label
        }"
      />
    </Spacer>
    <Title
      v-if="data.title !== ''"
      :data="{
        value: data.title,
        tag: data.tag_title,
        size: data.size_title
      }"
    />
    <RichText
      v-if="data.rich_text !== ''"
      :data="{ value: data.rich_text, size: isMobile ? 'm' : 'l', expanded: data.expanded }"
    />
    <Credits
      v-if="data.credits"
      :data="data.credits"
    />
    <Cta
      v-if="data.cta && data.cta !== ''"
      :data="{ ...data.cta, style: 'btn' }"
    />
  </Column>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Credits from '@/components/custom/credits';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
    Credits,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    sectionLayoutTemplate() {
      let col = {
        xs: 12,
        m: 12,
      };

      if (this.data.section_layout === 'columns') {
        col = {
          ...col,
          m: 6,
        };
      }

      if (this.data.section_layout === 'content-col-6' || this.data.section_layout === 'content-offset-6') {
        col = {
          ...col,
          m: 6,
        };
      }
      return col;
    },
  },
};
</script>
