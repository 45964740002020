<template>
  <main
    :class="[type === 'pages' ? 'main-pillar' : 'main-project']"
    :data-pillar="$route.params.pillar"
    :data-cover="!isProjectArchive && post.acf.cover_check ? 'true' : 'false'"
  >
    <div
      v-if="(post.acf.year || post.acf.project_type) && !isMobile && ((template === 'project' && $route.params.project) || (template === 'pillar' && !$route.params.project))"
      class="infos u-hidden--until-m"
    >
      <Grid :col="{ default: 12, m: 6 }">
        <div
          v-if="post.acf.year"
          class="year"
        >
          {{ post.acf.year }}
        </div>
        <div
          v-if="post.acf.project_type"
          class="type"
        >
          {{ post.acf.project_type }}
        </div>
      </Grid>
    </div>

    <div class="over-main">
      <Cover
        v-if="!isProjectArchive && post.acf && post.acf.cover_check"
        ref="cover"
        :data="post.acf.cover"
        :style="{
          backgroundColor: post.acf.work_background !== '' ? post.acf.work_background : null,
        }"
      />
      <div
        :class="['main-content', { 'main-asided': aside && aside.length }]"
        :data-template="!isProjectArchive && !post.acf.cover_check ? 'no-cover' : undefined"
        :style="{
          backgroundColor: post.acf.work_background !== '' ? post.acf.work_background : null,
          marginTop: !isProjectArchive && post.acf.cover_check && $route.meta.transition !== 'from-home' ? `${$store.state.delta * - 1}px` : null
        }"
      >
        <PageAside
          v-if="aside && aside.length"
          :data="aside"
        />
        <Spacer
          v-if="!isProjectArchive"
          y="l"
        >
          <Section
            v-for="(section, parentIndex) in post.acf.sections"
            :key="parentIndex"
            :layout="layout(section)"
          >
            <Wrapper>
              <Spacer :x="{ default: 's', m: 'l' }">
                <Grid
                  :col="section.section_layout === 'left-3-4' || section.section_layout === 'right-3-4' || section.section_layout === 'text' || section.section_layout === 'grid-3-left' || section.section_layout === 'grid-3-right' ? {} : { default: 12, m: section.section_layout === 'offset-6' || section.section_layout === 'col-6' ? 6 : 12 }"
                >
                  <Column
                    :size="section.section_layout === 'left-3-4' || section.section_layout === 'right-3-4' ? { default: 12, m: 9 } : section.section_layout === 'text' ? { default: 12, m: 7 } : section.section_layout === 'grid-3-left' || section.section_layout === 'grid-3-right' ? { default: 12, m: 9 } : false"
                    :offset="section.section_layout === 'offset-6' ? { m: 6 } : section.section_layout === 'right-3-4' ? { m: 3 } : section.section_layout === 'grid-3-right' ? { m: section.blocks.length < 3 ? 6 : 3 } : false"
                  >
                    <Grid
                      :col="section.section_layout === 'grid-3-left' || section.section_layout === 'grid-3-right' ? { default: 12, m: section.section_layout === 'grid-3-right' && section.blocks.length < 3 ? 6 : 4 } : {}"
                      :extra-class="[section.section_layout === 'columns' ? 'grid-columns' : false]"
                    >
                      <component
                        :is="components.get(block.acf_fc_layout)"
                        v-for="(block, index) in section.blocks"
                        :key="index"
                        :data="Object.assign(block, { section_layout: section.section_layout, expanded: aside && aside.length })"
                        :slug="block.acf_fc_layout === 'archive' ? slug : false"
                      />
                    </Grid>
                  </Column>
                </Grid>
              </Spacer>
            </Wrapper>
          </Section>
        </Spacer>
        <Spacer
          v-else
          :top="true"
        >
          <Fuoritempo
            :data="post.acf"
            :post="post"
            :slug="slug"
          />
        </Spacer>

        <Spacer
          v-if="slug === 'projects'"
          :bottom="{ default: 'm', m: 'l' }"
          :x="{ default: 's', m: 'l' }"
          :right="{ m: 'm' }"
          class="main-content studio-wrapper"
          data-template="no-cover"
        >
          <Search
            :tags="post.acf.tag_list"
            :placeholder="post.acf.search_placeholder"
          />
        </Spacer>
      </div>
    </div>
  </main>
</template>

<script>
import Cookies from 'js-cookie';
import gsap from 'gsap';
import { mapGetters } from 'vuex';

import components from '@/assets/js/components';
import medusa from '@/assets/js/observer';
import data from '@/mixins/data';
import lazyload from '@/mixins/lazyload';

import Section from '@/components/section';
import Content from '@/components/blocks/content';
import MediaBlock from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Placeholder from '@/components/blocks/placeholder';
import Ctas from '@/components/blocks/ctas';
import Cover from '@/components/custom/cover';
import Fuoritempo from '@/components/custom/fuoritempo';
import PageAside from '@/components/custom/aside';
import Search from '@/components/custom/search';

export default {
  name: 'Page',
  components: {
    Section,
    Content,
    MediaBlock,
    Repeater,
    List,
    Archive,
    Placeholder,
    Cover,
    Ctas,
    Fuoritempo,
    PageAside,
    Search,
  },
  mixins: [data, lazyload],
  data() {
    return {
      components,
    };
  },
  computed: {
    ...mapGetters(['homeVideo', 'videoEmbed', 'ui', 'videos', 'cover', 'isMobile', 'noContent']),
    isProjectArchive() {
      return this.template === 'project' && this.post.gds_taxonomies.pillar.find((pillar) => pillar.slug === 'fuoritempo' || pillar.slug === 'ongoing-rd');
    },
    isPillarArchive() {
      return this.template === 'pillar' && (this.$route.params.pillar === 'fuoritempo' || this.$route.params.pillar === 'ongoing-rd');
    },
    aside() {
      return this.post && this.post.acf && this.post.acf.sections && this.post.acf.sections.length ? this.post.acf.sections.map(((section) => section.label)).filter(Boolean) : undefined;
    },
  },
  watch: {
    ui(val) {
      if (val.status === 'video') {
        const mainContent = this.$el.parentNode.querySelector('.main-project .main-content');
        const infos = this.$el.querySelector('.infos');
        gsap.killTweensOf(mainContent);
        gsap.to(mainContent, {
          y: val.show ? 0 : (this.$store.state.delta) + 1,
          clearProps: val.show ? 'y' : '',
          ease: 'power2.out',
          duration: 0.4,
        });

        if (infos) {
          gsap.killTweensOf(infos);
          gsap.to(infos, {
            autoAlpha: val.show ? 1 : 0,
            ease: 'power2.out',
            duration: 0.4,
          });
        }
      }
    },
  },
  created() {
    // this.$root.$el.dataset.back = this.isProjectArchive ? 'false' : this.post.acf.back === false ? 'false' : 'true';
    this.$root.$el.dataset.cover = !this.isProjectArchive && this.post.acf.cover_check ? 'true' : 'false';
  },
  mounted() {
    // this.$aion.add(this.checkCurrentVideo, 'checkCurrentVideo', true);
    if (this.post && this.slug && this.post.acf.notifications && this.post.acf.notifications.custom && this.post.acf.notifications.custom.repeater) {
      this.$aion.add(this.checkNotification, 'checkNotification', true);
    }

    // if (this.videos.length > 0) {
    //   this.$nextTick(() => {
    //     if (medusa.ref.idList.includes(`page-${this.slug}`)) {
    //       medusa.ref.removeTarget(`page-${this.slug}`);
    //     }
    //     medusa.ref.addTarget({
    //       id: `page-${this.slug}`,
    //       container: this.$parent.$el,
    //       threshold: 0,
    //       nodes: this.videos.map((video) => video.media),
    //       mode: 'default',
    //       callback: this.playOrPause,
    //     });
    //   });
    // }

    if ((this.$route.params.pillar === 'fuoritempo' || this.$route.params.pillar === 'ongoing-rd') && this.$route.params.project) {
      this.isFuoritempoProject = true;
      this.$store.commit('SET_UI', { status: 'back', show: false });
    }

    this.$nextTick(() => {
      this.checkCurrentVideo();
    });
  },
  beforeDestroy() {
    // this.$aion.remove('checkCurrentVideo');

    this.$aion.remove('checkNotification');
    if (medusa.ref.idList.includes(`page-${this.slug}`)) {
      medusa.ref.removeTarget(`page-${this.slug}`);
    }
    this.$store.commit('EMPTY_VIDEOS');

    if (this.isFuoritempoProject) {
      this.$store.commit('SET_UI', { status: 'back', show: true });
      this.isFuoritempoProject = false;
    }

    if (this.post && this.slug && this.post.acf.notifications && this.post.acf.notifications.custom && this.post.acf.notifications.custom.repeater) {
      this.post.acf.notifications.custom.repeater.map((notification, index) => {
        const id = `${this.slug}-${index}`;
        return this.$store.commit('REMOVE_NOTIFICATION', id);
      });
    }
  },
  methods: {
    layout(section) {
      const {
        section_classes, section_layout, section_layout_custom,
        label,
      } = section;

      return {
        section_classes, section_layout, section_layout_custom, label,
      };
    },
    setGap(blocks) {
      const array = [];

      const blockFiltered = blocks.filter((block) => block.acf_fc_layout === 'media' || block.acf_fc_layout === 'content');
      if (blockFiltered.length === 2) {
        if (blockFiltered[0].acf_fc_layout === 'media') array.push('row-gap-custom-xxs');
        if (blockFiltered[0].acf_fc_layout === 'content') array.push('row-gap-custom-xxs');
      }

      return array;
    },
    checkCurrentVideo() {
      if (this.$route.name === 'Home') return;
      const cover = this.$refs?.cover?.$refs?.videoCover?.$refs?.media?.plyr;

      if ((!this.post.acf.cover_check && this.template === 'project') || (this.template === 'pillar' && !this.$route.params.project)) {
        if (this.homeVideo && this.homeVideo.id) this.homeVideo.pause();
      }

      if (cover) {
        cover.play()
          .then(() => {
            if (this.homeVideo && this.homeVideo.id) this.homeVideo.pause();
          });
      }
    },
    checkNotification() {
      if (window.pageYOffset > this.$el.offsetHeight * 0.5) {
        this.$aion.remove('checkNotification');
        this.post.acf.notifications.custom.repeater.map((notification, index) => {
          notification.id = `${this.slug}-${index}`;
          notification.status = !Cookies.get(`april-${this.slug}-${index}`);
          return this.$store.commit('ADD_NOTIFICATION', notification);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-pillar {
  .main-content {
    color: var(--color-pillar, $white);
  }
}

.main-project {
  .main-content {
    color: var(--color-project, $white);
  }
}

.main-content {
  &[data-template="no-cover"] {
    padding-top: 150px;
  }

  &.main-asided {
    @include mq(m) {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }
  }
}

section+section {
  padding-top: $unit * 12.5;

  @include mq($until: m) {
    padding-top: $unit * 2;
  }
}

section[id]+section[id] {
  @include mq($until: m) {
    padding-top: $unit * 6.25;
  }
}

section.section--no-margin {
  margin-top: map-get($spacer, s);
}

.infos {
  position: fixed;
  top: 24px;
  left: 50%;
  width: 50%;
  z-index: 25;
  color: var(--color, $white);
  pointer-events: none;
  padding-right: 136px;

  @extend %typo--m;
}
</style>
