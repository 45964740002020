export default {
  ADD_ITEM(state, { type, item }) {
    // eslint-disable-next-line no-prototype-builtins
    if (item && type && !state.wp[type].hasOwnProperty(item.id)) {
      state.wp[type][item.slug] = item;
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state.wp[type].requests.push(request);
  },
  SET_LOADING(state, loading) {
    state.wp.site.loading = loading;
  },
  SET_DOC_TITLE(state, title) {
    state.site.docTitle = title;
  },
  SET_TOUCH(state, value) {
    state.touch = value;
  },
  SET_MOBILE(state, value) {
    state.mobile = value;
  },
  SET_IPAD(state, value) {
    state.ipad = value;
  },
  SET_BREAKPOINTS(state, value) {
    state.breakpoints = value;
  },
  SET_TRANSITION_STATUS(state, value) {
    state.transition.status = value;
  },
  SET_SCROLL_POSITION(state, value) {
    state.transition.scrollPosition = value;
  },
  SET_HOME_READY(state) {
    state.home.ready = true;
  },
  SET_HOME_VIDEO(state, value) {
    state.home.video.current = value;
  },
  SET_HOME_VIDEOS(state, value) {
    state.home.video.items = [...state.home.video.items, value];
  },
  SET_HOME_ITEM(state, value) {
    state.home.item = value;
  },
  SET_HOME_ITEM_INDEX(state, value) {
    state.home.itemIndex = value;
  },
  SET_HOME_SLIDE_INDEX(state, value) {
    state.home.slideIndex = value;
  },
  SET_HOME_VIDEO_INDEX(state, value) {
    state.home.videoIndex = value;
  },
  SET_HOME_PILLAR_INDEX(state, value) {
    state.home.pillarIndex = value;
  },
  SET_HOME_PILLAR_INDEX_NAV(state, value) {
    state.home.pillarIndexNav = value;
  },
  SET_ARCHIVE_READY(state) {
    state.archive.ready = true;
  },
  SET_ARCHIVE_STATUS(state, value) {
    state.archive.status = value;
  },
  SET_ARCHIVE_PREV(state, value) {
    state.archive.prev = value;
  },
  SET_VIDEO(state, value) {
    state.video.current = value;
  },
  SET_VIDEOS(state, value) {
    state.video.items = [...state.video.items, value];
  },
  EMPTY_VIDEOS(state) {
    state.video.items = [];
    state.video.current = null;
    state.video.isEmbed = null;
  },
  SET_VIDEO_EMBED(state, value) {
    state.video.isEmbed = value;
  },
  SET_UI(state, value) {
    state.ui = value;
  },
  SET_AUDIO(state, value) {
    state.audio = value;
  },
  SET_LOADER(state, value) {
    state.loader = value;
  },
  SET_CURRENT_COLOR(state, value) {
    state.currentColor = {
      color: value[0],
      template: value[1] || 'pillar',
    };
  },
  SET_CURRENT_VIDEO(state, value) {
    state.currentVideo = value;
  },
  CHECK_COVER(state, value) {
    state.cover = value;
  },
  ADD_NOTIFICATION(state, value) {
    state.notifications.push(value);
  },
  REMOVE_NOTIFICATION(state, value) {
    state.notifications = state.notifications.filter((el) => el.id !== value);
  },
  SET_PREV_SCROLL(state, value) {
    state.prevScroll = value;
  },
  SET_FORCE(state, value) {
    state.force = value;
  },
  TOGGLE_MENU(state) {
    state.menuStatus = !state.menuStatus;
  },
  SET_MENU(state, value) {
    state.menuStatus = value;
  },
  SET_ASIDE_MOBILE(state, value) {
    state.asideMobile = value;
  },
};
