<template>
  <Column size="12">
    <Spacer
      v-if="data.title"
      bottom="m"
    >
      <Title :data="{ value: data.title, tag: 'h3', size: 'm' }" />
    </Spacer>
    <Grid
      v-if="items.length > 0 && !data.style.classes.includes('grid-fuoritempo')"
      :col="gridCol"
      :template="gridTemplate"
      :data-layout="data.style.type !== 'default' ? data.style.type : null"
      :extra-class="['row-gap-custom-xxs', data.style.classes !== '' ? data.style.classes.split(' ') : null]"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <Thumb :data="item" />
      </div>
    </Grid>

    <div v-if="custom.length > 0 && data.style.classes.includes('grid-fuoritempo')">
      <Spacer
        class="ft-col"
        bottom="xxs"
      >
        <Grid
          :extra-class="['row-gap-custom-xxs']"
          :col="{ default: 12, m: 6 }"
        >
          <div
            key="0"
            class="item"
          >
            <Thumb :data="custom[0][0]" />
          </div>
          <Grid
            :col="{ default: 6, m: 6 }"
            :extra-class="['row-gap-custom-xxs']"
          >
            <div
              v-for="(item, index) in custom[0]"
              v-if="index > 0"
              :key="index"
              class="item"
            >
              <Thumb :data="item" />
            </div>
          </Grid>
        </Grid>
      </Spacer>
      <Spacer
        v-if="custom.length > 1"
        class="ft-row"
        bottom="xxs"
      >
        <Grid
          :col="gridCol"
          :extra-class="['row-gap-custom-xxs']"
        >
          <div
            v-for="(item, index) in custom[1]"
            :key="index"
            class="item"
          >
            <Thumb :data="item" />
          </div>
        </Grid>
      </Spacer>
    </div>

    <Spacer
      v-else-if="items.length === 0"
      y="xs"
    >
      <div v-if="customPlaceholder">
        <Grid
          v-if="first"
          :col="gridCol"
        >
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
          <div class="custom-placeholder" />
        </Grid>
        <span v-else>Nothing to show.</span>
      </div>
      <div v-else-if="data.style.classes.includes('grid-fuoritempo')">
        {{ placeholder }}
      </div>
      <Grid
        v-else
        :col="gridCol"
      >
        <div
          v-for="i in preloadItems"
          :key="i"
          class="custom-placeholder"
        />
      </Grid>
    </Spacer>

    <div
      v-show="(items.length > 0 || custom.length > 0) && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />
  </Column>
</template>

<script>
import archive from '@/mixins/archive';

import Title from '@/components/typo/title';
import Thumb from '@/components/thumb';

export default {
  name: 'Archive',
  components: {
    Title,
    Thumb,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => { },
    },
    slug: {
      type: String,
      required: true,
    },
    customPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? (12 / this.items.length).toString() : layout_size,
          };
        });
      } else {
        cols = {
          default: 6,
          // m: (12 / (this.items?.length || this.data.categories?.length || this.data.posts?.length)).toString() || 3,
          m: 3,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  visibility: hidden;
}

.custom-placeholder {
  background: $grey-l;
  width: 100%;
  opacity: 0.2;
  height: 0;
  padding-bottom: 100%;
}
</style>
