<template>
  <div class="thumb">
    <router-link
      v-if="!data.acf.disabled"
      :to="data.link"
      :data-cursor="data.taxonomy ? data.name : data.title.rendered ? data.title.rendered : data.title.title"
      data-cursor-color="#ffffff"
    >
      <Figure
        v-if="data.gds_featured_image"
        :data="data.gds_featured_image"
        :ratio-from-props="100"
      />
      <Spacer
        v-if="isMobile || isTouch"
        bottom="xs"
        top="xxs"
      >
        <Label
          :data="{ value: data.taxonomy ? data.name : data.title.rendered ? data.title.rendered : data.title.title, size: 's', tag: 'h3' }"
        />
      </Spacer>
    </router-link>
    <div
      v-else
      class="disabled"
      :data-cursor="data.taxonomy ? data.name : data.title.rendered ? data.title.rendered : data.title.title"
      data-cursor-color="#ffffff"
    >
      <Figure
        v-if="data.gds_featured_image"
        :data="data.gds_featured_image"
        :ratio-from-props="100"
      />
      <Spacer
        v-if="isMobile || isTouch"
        bottom="xs"
        top="xxs"
      >
        <Label
          :data="{ value: data.taxonomy ? data.name : data.title.rendered ? data.title.rendered : data.title.title, size: 's', tag: 'h3' }"
        />
      </Spacer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isTouch']),
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
