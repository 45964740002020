export default {
  menu: (state) => (name) => state.wp.menus[name],
  request:
    (state) => ({ type, params }) => {
      let res = null;
      const slugs = [];
      if (params.slug && params.slug.length > 0) {
        params.slug.forEach((slug) => {
          if (state.wp[type][slug]) slugs.push(slug);
        });
        return slugs.length > 0 ? slugs : null;
      }

      const request = state.wp[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          res = Object.keys(req.params).every((key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]));
        }
        return res;
      });

      if (request) return request.data;
      return res;
    },
  totalPages:
    (state, getters) => ({ type, params }) => {
      const request = getters.request({ type, params });
      return request ? request.totalPages : 0;
    },
  requestedItems:
    (state, getters) => ({ type, params }) => {
      const request = getters.request({ type, params });
      return request ? request.data.map((id) => state.wp[type][id]) : [];
    },
  singleBySlug:
    (state) => ({ type, slug }) => {
      let single = null;
      // eslint-disable-next-line
      for (const id in state.wp[type]) {
        if (decodeURI(state.wp[type][id].slug) === slug) {
          single = state.wp[type][id];
        }
      }
      return single;
    },
  postTypeBySlug:
    (state) => ({ slug }) => {
      let post = null;
      // eslint-disable-next-line
      for (const key in state.wp.postTypes) {
        // eslint-disable-next-line no-prototype-builtins
        if (state.wp.postTypes.hasOwnProperty(key)) {
          if (key === slug) {
            post = state.wp.postTypes[key];
          }
        }
      }
      return post;
    },
  singleById:
    (state) => ({ type, id }) => state.wp[type][id],
  isTouch: (state) => state.touch,
  isMobile: (state) => state.mobile,
  isIpad: (state) => state.ipad,
  breakpoints: (state) => state.breakpoints,

  transition: (state) => state.transition.status,
  isHomeReady: (state) => state.home.ready,
  isArchiveReady: (state) => state.archive.ready,
  archiveStatus: (state) => state.archive.status,
  archivePrevRoute: (state) => state.archive.prev,
  homeVideo: (state) => state.home.video.current,
  homeVideos: (state) => state.home.video.items,
  homeItem: (state) => state.home.item,
  homeItemIndex: (state) => state.home.itemIndex,
  homeSlideIndex: (state) => state.home.slideIndex,
  homePillarIndex: (state) => state.home.pillarIndex,
  homePillarIndexNav: (state) => state.home.pillarIndexNav,
  videos: (state) => state.video.items,
  video: (state) => state.video.current,
  videoEmbed: (state) => state.video.isEmbed,
  ui: (state) => state.ui,
  audio: (state) => state.audio,
  loader: (state) => state.loader,
  notifications: (state) => state.notifications,
  currentColor: (state) => state.currentColor,
  cover: (state) => state.cover,
  noContent: (state) => state.noContent,
  prevScroll: (state) => state.prevScroll,
  currentVideo: (state) => state.currentVideo,
  forceFromMenu: (state) => state.force,
  projectInHome: (state) => {
    let signal = false;
    const { project } = state.route.params;
    if (state.route.from && state.route.from.params.pillar && !state.route.from.params.project) return signal;
    if (!project) return signal;

    state.wp.pages.index.acf.navigation.forEach((nav) => {
      nav.content.forEach((content) => {
        if (!content.custom && content.project.post_name === project) {
          signal = true;
        }
      });
    });

    return signal;
  },
  menuStatus: (state) => state.menuStatus,
  asideMobile: (state) => state.asideMobile,
};
