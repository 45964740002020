<template>
  <div
    :class="[
      'spacer',
      ...computedAll,
      ...computedX,
      ...computedY,
      ...computedTop,
      ...computedBottom,
      ...computedLeft,
      ...computedRight,
      template !== '' ? `spacer--${template}` : null,
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    all: {
      type: [Object, String, Boolean],
      default: false,
    },
    x: {
      type: [Object, String, Boolean],
      default: false,
    },
    y: {
      type: [Object, String, Boolean],
      default: false,
    },
    top: {
      type: [Object, String, Boolean],
      default: false,
    },
    bottom: {
      type: [Object, String, Boolean],
      default: false,
    },
    left: {
      type: [Object, String, Boolean],
      default: false,
    },
    right: {
      type: [Object, String, Boolean],
      default: false,
    },
    useMargin: {
      type: Boolean,
      default: false,
    },
    template: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedAll() {
      const { all, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof all === 'boolean' && all) {
        array.push(`spacer--${type}--a`);
      } else if (typeof all === 'string') {
        array.push(`spacer--${type}--a--${all}`);
      } else if (typeof all === 'object') {
        Object.keys(all).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--a-${key}--${all[key]}`);
          } else {
            array.push(`spacer--${type}--a--${all[key]}`);
          }
        });
      }

      return array;
    },
    computedY() {
      const { y, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof y === 'boolean' && y) {
        array.push(`spacer--${type}--y`);
      } else if (typeof y === 'string') {
        array.push(`spacer--${type}--y--${y}`);
      } else if (typeof y === 'object') {
        Object.keys(y).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--y-${key}--${y[key]}`);
          } else {
            array.push(`spacer--${type}--y--${y[key]}`);
          }
        });
      }

      return array;
    },
    computedX() {
      const { x, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof x === 'boolean' && x) {
        array.push(`spacer--${type}--x`);
      } else if (typeof x === 'string') {
        array.push(`spacer--${type}--x--${x}`);
      } else if (typeof x === 'object') {
        Object.keys(x).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--x-${key}--${x[key]}`);
          } else {
            array.push(`spacer--${type}--x--${x[key]}`);
          }
        });
      }

      return array;
    },
    computedTop() {
      const { top, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof top === 'boolean' && top) {
        array.push(`spacer--${type}--t`);
      } else if (typeof top === 'string') {
        array.push(`spacer--${type}--t--${top}`);
      } else if (typeof top === 'object') {
        Object.keys(top).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--t-${key}--${top[key]}`);
          } else {
            array.push(`spacer--${type}--t--${top[key]}`);
          }
        });
      }

      return array;
    },
    computedBottom() {
      const { bottom, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof bottom === 'boolean' && bottom) {
        array.push(`spacer--${type}--b`);
      } else if (typeof bottom === 'string') {
        array.push(`spacer--${type}--b--${bottom}`);
      } else if (typeof bottom === 'object') {
        Object.keys(bottom).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--b-${key}--${bottom[key]}`);
          } else {
            array.push(`spacer--${type}--b--${bottom[key]}`);
          }
        });
      }

      return array;
    },
    computedLeft() {
      const { left, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof left === 'boolean' && left) {
        array.push(`spacer--${type}--l`);
      } else if (typeof left === 'string') {
        array.push(`spacer--${type}--l--${left}`);
      } else if (typeof left === 'object') {
        Object.keys(left).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--l-${key}--${left[key]}`);
          } else {
            array.push(`spacer--${type}--l--${left[key]}`);
          }
        });
      }

      return array;
    },
    computedRight() {
      const { right, useMargin, template } = this;
      if (template) return [];
      const type = useMargin ? 'margin' : 'padding';
      const array = [];
      if (typeof right === 'boolean' && right) {
        array.push(`spacer--${type}--r`);
      } else if (typeof right === 'string') {
        array.push(`spacer--${type}--r--${right}`);
      } else if (typeof right === 'object') {
        Object.keys(right).forEach((key) => {
          if (key !== 'default') {
            array.push(`spacer--${type}--r-${key}--${right[key]}`);
          } else {
            array.push(`spacer--${type}--r--${right[key]}`);
          }
        });
      }

      return array;
    },
  },
};
</script>

<style>

</style>
