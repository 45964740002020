export default {
  data() {
    return {
      post: null,
    };
  },
  created() {
    this.post = this.$store.state.wp[this.type][this.slug];
    this.checkSettings(this.$route);
  },
  watch: {
    slug() {
      this.post = this.$store.state.wp[this.type][this.slug];
    },
    $route(to) {
      this.checkSettings(to);
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    template: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkSettings(to) {
      if (this.post.acf.work_color && this.post.acf.work_color !== '') {
        this.$store.commit('SET_CURRENT_COLOR', [this.post.acf.work_color, this.template]);
      }
      if (to.params.project && this.template === 'project') {
        this.$store.commit('CHECK_COVER', this.post.acf.cover_check);
      } else if ((this.template === 'pillar' && !to.params.project) || (to.params && to.params.pillar === 'projects')) {
        this.$store.commit('CHECK_COVER', false);

        if (!this.post.acf.work_color || this.post.acf.work_color === '') {
          this.$store.commit('SET_CURRENT_COLOR', ['#ffffff', 'pillar']);
        }
      } else if (!to.params.pillar) {
        this.$store.commit('SET_CURRENT_COLOR', ['#ffffff']);
        this.$store.commit('CHECK_COVER', false);
      }
    },
  },
};
