import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

const customNotifications = state.options.notifications.custom.repeater;
let parseCustomNotifications = [];

if (customNotifications && customNotifications.length > 0) {
  customNotifications.forEach((notification, index) => {
    notification.id = `site-${index}`;
    notification.status = !Cookies.get(`april-site-${index}`);
  });

  parseCustomNotifications = customNotifications;
}

const noContent = [];
Object.keys(state.pages).forEach((key) => {
  if (key !== 'requests' && state.pages[key].acf.no_content) {
    noContent.push(key);
  }
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wp: state,
    breakpoints: null,
    touch: null,
    mobile: null,
    ipad: null,
    transition: {
      status: true,
      scrollPosition: 0,
    },
    noContent,
    currentColor: '#ffffff',
    currentVideo: null,
    cover: null,
    delta: window.innerHeight * 0.5,
    prevScroll: 0,
    force: false,
    home: {
      ready: false,
      video: {
        current: false,
        items: [],
      },
      item: null,
      videoIndex: 0,
      pillarIndex: 0,
      slideIndex: 0,
      itemIndex: 0,
      pillarIndexNav: -1,
    },
    video: {
      items: [],
      current: null,
      isEmbed: false,
    },
    archive: {
      ready: false,
      status: false,
      prev: null,
    },
    menuStatus: false,
    asideMobile: false,
    ui: {
      status: 'idle',
      show: true,
    },
    audio: false,
    loader: true,
    notifications: [
      // {
      //   id: 'cookies',
      //   ...state.options.notifications.cookies,
      //   status: !Cookies.get('april-cookie'),
      // },
      {
        id: 'audio',
        ...state.options.notifications.audio,
        status: !state.audio,
      },
      ...parseCustomNotifications,
    ],
  },
  mutations,
  getters,
  actions,
  modules: {},
});
