<template>
  <header>
    <Spacer
      template="header"
      class="header-wrapper"
      :class="[{ 'header-white': menuStatus }, { 'header-bg': asideMobile }]"
    >
      <div class="btns">
        <div
          class="icon-left"
          data-ui="back"
        >
          <Icon
            v-show="($route.name !== 'Home' && $route.name !== 'Archive') && !videoEmbed"
            name="back"
            :fn="goBack"
          />
          <transition name="delay">
            <Icon
              v-show="videoEmbed"
              name="close"
              :fn="goBack"
            />
          </transition>
        </div>
        <div
          data-ui="archive"
          :class="['icon-right', videoEmbed ? 'archive-hide' : false]"
        >
          <Icon
            :name="menuStatus ? 'close' : 'archive'"
            :fn="toggleMenu"
          />
        </div>
        <div
          data-ui="volume"
          :class="['volume', menuStatus && isMobile ? 'volume-hide' : false, videoEmbed ? 'volume-anim' : false]"
        >
          <Icon
            :name="volume"
            :fn="switchVolume"
            :class="[{ 'not-active': menuStatus }, 'audio']"
          />
        </div>
      </div>
      <AppMenu
        :class="{ 'not-active': (archiveStatus && isProjectArchive && $route.params.project) }"
        data-ui="logo"
      />
    </Spacer>
    <div
      v-show="$route.name === 'Home'"
      class="shadow"
    />
  </header>
</template>

<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';

import AppMenu from '@/components/ui/menu';
import Icon from '@/components/ui/icon';

export default {
  name: 'Header',
  components: {
    Icon,
    AppMenu,
  },
  data() {
    return {
      volume: 'volume_off',
      isAnim: true,
      force: false,
      status: 'show',
      scroll: 0,
      isVisibleUi: false,
      timeout: null,
      prevScroll: -1,
      mouse: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['audio', 'ui', 'isMobile', 'isTouch', 'isTouch', 'videoEmbed', 'currentColor', 'archiveStatus', 'projectInHome', 'noContent', 'archivePrevRoute', 'menuStatus', 'asideMobile']),
    isProjectArchive() {
      return this.$route.params.pillar === 'fuoritempo' || this.$route.params.pillar === 'ongoing-rd';
    },
  },
  watch: {
    ui(val) {
      if (val.status !== 'logo') {
        if (val.show) {
          this.$el.classList.remove('no-pointer');
        } else {
          this.$el.classList.add('no-pointer');
        }
      }
      this.animToggleUi(val);
    },
    audio(val) {
      this.volume = !val ? 'volume_off' : 'volume_on';
    },
  },
  mounted() {
    if (!this.isMobile) {
      gsap.set(this.$el, {
        yPercent: -100,
      });
    }

    gsap.to(this.$el, {
      yPercent: 0,
      ease: 'power2.out',
      duration: 0.5,
      delay: 0.4,
      clearProps: 'all',
      onComplete: () => {
        this.isAnim = false;
      },
    });

    window.addEventListener('mousemove', (e) => {
      if (!this.isTouch) {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;

        this.checkToggleUi();
      }
    });

    window.addEventListener('wheel', () => {
      if (!this.isTouch) {
        this.checkToggleUi();
      }
    });
  },
  methods: {
    switchVolume() {
      this.$store.commit('SET_AUDIO', !this.audio);
    },
    toggleMenu() {
      this.$store.commit('TOGGLE_MENU');
    },
    goBack() {
      if (this.videoEmbed) {
        this.$bus.$emit('closeVideo');
      } else if (this.$store.state.route.from && this.$store.state.route.from.name === 'Archive' && this.archiveStatus) {
        this.$router.push('/archive');
      } else if (this.$store.state.route.name === 'Archive' && this.archivePrevRoute) {
        this.$router.push(this.archivePrevRoute);
        this.$store.commit('SET_ARCHIVE_STATUS', false);
        this.$store.commit('SET_ARCHIVE_PREV', null);
      } else if ((!this.projectInHome) || (this.$route.params.project && this.$store.state.route.from && this.$store.state.route.from.pillar && !this.$store.state.route.from.project)) {
        let route = '/';
        if (this.$route.params.project && !this.noContent.includes(this.$route.params.pillar)) {
          route = `/${this.$route.params.pillar}`;
        }

        if (this.$route.params.project && (!this.projectInHome || (this.$route.params.project && this.$store.state.route.from && this.$store.state.route.from.pillar && !this.$store.state.route.from.project))) {
          const { from } = this.$store.state.route;
          if (from && from.params.pillar && !this.noContent.includes(from.params.pillar)) {
            route = `/${from.params.pillar}`;
          }
        }
        this.$router.push(route);
      } else {
        this.$router.push('/');
      }
    },
    goArchive() {
      this.$router.push('/archive');
    },
    checkToggleUi() {
      if (!this.isVisibleUi) {
        this.isVisibleUi = true;
        this.fadeUi(true);
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.isVisibleUi && this.mouse.y >= 100 && !this.menuStatus) {
          this.isVisibleUi = false;
          this.fadeUi(false);
        }
      }, 6000);
    },
    fadeUi(dir) {
      this.isAnim = true;
      const els = [this.$el];
      if (this.videoEmbed) {
        els.push(this.videoEmbed.elements.wrapper.closest('.cover').querySelector('.ui-bottom'));
      }

      if (this.$route.name === 'Home' && !this.menuStatus) {
        els.push(document.querySelector('.main-home .status'));
        els.push(...Array.from(document.querySelectorAll('.main-home .title')));
      }

      const infos = document.querySelector('.infos');
      if (infos && !this.videoEmbed) {
        els.push(infos);
      }

      gsap.killTweensOf(els, 'autoAlpha');

      gsap.to(els, {
        autoAlpha: dir ? 1 : 0,
        ease: 'power2.out',
        duration: 0.3,
        clearProps: dir ? 'all' : '',
        onComplete: () => {
          this.isAnim = false;
        },
      });
    },
    animToggleUi(val) {
      this.isAnim = true;
      const els = val.status === 'video'
        ? this.$el.querySelector('[data-ui="logo"]') : val.status === 'back'
          ? Array.from(this.$el.querySelectorAll('[data-ui]:not([data-ui="back"])')) : this.$el;

      gsap.killTweensOf(els);
      gsap.to(els, {
        yPercent: val.show ? 0 : !this.isMobile && !this.isTouch ? -100 : 0,
        autoAlpha: val.show ? 1 : 0,
        clearProps: val.show ? 'all' : '',
        ease: 'power2.out',
        duration: 0.3,
        onComplete: () => {
          this.isAnim = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  pointer-events: none;
  // mix-blend-mode: difference;
  color: var(--color, $white);

  .header-white {
    color: $white;
  }

  .header-bg {
    background: var(--background, $black);
  }

  @include mq($until: m) {
    height: 100%;

    .is-home & {
      position: absolute;
    }
  }

  &>div {
    position: relative;
  }

  .header-wrapper {
    @include mq($until: m) {
      display: flex;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;

      .is-home & {
        height: 100%;
      }
    }
  }

  &.no-pointer {
    [data-ui] {
      // pointer-events: none;
    }

    ::v-deep .slide {
      pointer-events: none;
    }
  }

  .icon-left {
    transform: translate3d(0, 0, 0);
    left: 0;
  }

  .icon-right {
    right: 0;
  }

  [data-ui="volume"] {
    right: 68px;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 150px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);

    @include mq(m) {
      height: 400px;
      background: url('~@/assets/img/gradient.png');
    }
  }
}

.not-active {
  opacity: 0 !important;
  visibility: hidden;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}

.volume {
  position: relative;
  transition: transform 0.2s $ease-custom;
}

.volume-hide {
  visibility: hidden !important;
  pointer-events: none !important;
}

.archive-hide {
  pointer-events: none !important;
  opacity: 0;
}

.volume-anim {
  @include mq(m) {
    transform: translate3d(68px, 0, 0);
  }
}

.btns {
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  height: 68px;
  // height: 100%;
  // transform: translate3d(0, -50%, 0);

  @include mq($until: m) {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    bottom: 0;
    transform: none;

    .is-home & {
      position: absolute;
    }
  }

  [data-ui] {
    position: absolute;
    // top: 50%;
    pointer-events: auto;
    // transform: translate3d(0, -50%, 0);

    &>* {
      position: relative;
      padding: 24px;
      box-sizing: content-box;
    }

    @include mq($until: m) {
      transform: none;

      &[data-ui="volume"],
      &[data-ui="archive"] {
        top: auto;
        // bottom: map-get($spacer, m);
        bottom: 0;
      }
    }
  }

  [data-ui="back"] {
    // left: -30px;
  }

  [data-ui="archive"] {
    @include mq($until: m) {
      // right: -30px;
    }
  }

  [data-ui="volume"] {
    // right: -30px;

    @include mq($until: m) {
      left: 0px;
      right: auto;
    }
  }
}

.delay-enter-active,
.delay-leave-active {
  transition: visibility 0s;
}

.delay-enter-active {
  transition-delay: 0.3s;
}

.delay-enter,
.delay-leave-to {
  visibility: hidden;
}
</style>
