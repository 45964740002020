<template>
  <div
    id="app"
    :class="[
      (!projectInHome && $route.params.project) ||
        ($route.params.project &&
          $store.state.route.from &&
          $store.state.route.from.pillar &&
          !$store.state.route.from.project)
        ? 'in-overlay'
        : false,
      $route.name === 'Home' ? 'is-home' : false,
    ]"
  >
    <AppHeader v-if="isHomeReady" />
    <!-- Home -->
    <keep-alive>
      <main
        v-show="!loader"
        ref="home"
        :class="['main-home']"
      >
        <Home />
      </main>
    </keep-alive>

    <div
      v-show="$route.name !== 'Home' || archiveStatus"
      ref="overlay"
      :class="[
        'main-overlay',
        $route.meta.transition === 'from-home' && cover ? 'add-before' : false,
        (!projectInHome && $route.params.project) ||
          ($route.params.project &&
            $store.state.route.from &&
            $store.state.route.from.pillar &&
            !$store.state.route.from.project)
          ? 'overlay-fixed-project'
          : false,
      ]"
      :data-pillar="$route.params.pillar || 'archive'"
    >
      <div>
        <!-- Default -->
        <transition
          appear
          :css="false"
          @enter="enter"
          @leave="leave"
        >
          <router-view
            v-if="
              isHomeReady && $route.name !== 'Home' && !noContent.includes($route.params.pillar)
            "
            v-show="!projectInHome"
            ref="pillar"
            :key="prevPath"
            name="default"
          />
        </transition>

        <!-- Overlay -->
        <transition
          appear
          :css="false"
          @enter="enter"
          @leave="leave"
        >
          <router-view
            v-if="$route.params.project && $route.name !== 'Home' && isHomeReady"
            :key="$route.fullPath"
            ref="projectRef"
            name="overlay"
          />
        </transition>
      </div>
    </div>

    <AppCursor v-if="!loader && !isTouch" />

    <Notifications v-if="!loader" />
    <div class="disable" />

    <transition
      appear
      :css="false"
      @enter="enterArchive"
      @leave="leaveArchive"
    >
      <AppOffCanvas
        v-if="menuStatus"
        ref="offcanvas"
      />
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import medusa from '@/assets/js/observer';
import transitions from '@/mixins/transitions';
import { isTouchDevice, is, isIOS } from '@/assets/js/utils';

import AppHeader from '@/components/ui/header';
import Notifications from '@/components/ui/notifications';
import Home from '@/views/Home';
import AppCursor from '@/components/ui/cursor';
import AppOffCanvas from '@/components/ui/offcanvas';
// import Cta from '@/components/typo/cta';

export default {
  name: 'App',
  components: {
    AppHeader,
    Home,
    AppCursor,
    // Cta,
    Notifications,
    AppOffCanvas,
  },
  mixins: [transitions],
  data() {
    return {
      prevOrientation: window.innerWidth > window.innerHeight,
      isAnimating: false,
    };
  },
  computed: {
    ...mapGetters([
      'isHomeReady',
      'isArchiveReady',
      'archiveStatus',
      'homeItem',
      'homeVideo',
      'homeItemIndex',
      'loader',
      'isMobile',
      'cover',
      'currentColor',
      'isTouch',
      'isTouch',
      'projectInHome',
      'noContent',
      'videoEmbed',
      'menuStatus',
    ]),
    prevPath() {
      return this.$route.params.project ? this.$store.state.route.from.path : this.$route.path;
    },
  },
  watch: {
    isHomeReady(val) {
      if (
        val
        && ((!this.projectInHome && this.$route.params.project)
          || (this.$route.params.project
            && this.$store.state.route.from
            && this.$store.state.route.from.pillar
            && !this.$store.state.route.from.project))
      ) {
        this.$nextTick(() => {
          if (this.$refs.projectRef) {
            disableBodyScroll(this.$refs.projectRef.$el);
          }
        });
      }
    },
    menuStatus(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.offcanvas) {
            disableBodyScroll(this.$refs.offcanvas.$el);
          }
        });
      } else {
        clearAllBodyScrollLocks();
      }
    },
    $route(to, from) {
      this.isAnimating = false;
      if (this.isHomeReady) return;
      if (
        (!this.projectInHome && to.params.project)
        || (to.params.project && from && from.pillar && !from.project)
      ) {
        this.$nextTick(() => {
          if (this.$refs.projectRef) disableBodyScroll(this.$refs.projectRef.$el);
        });
      } else {
        clearAllBodyScrollLocks();
      }
    },
    isMobile(val) {
      if (val || isIOS()) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } else {
        document.documentElement.style.setProperty('--vh', '1vh');
      }
    },
    isTouch(val) {
      if (val || isIOS()) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } else {
        document.documentElement.style.setProperty('--vh', '1vh');
      }
    },
    currentColor(val) {
      if (!val.template) document.documentElement.style.setProperty('--color', val.color);
      if (val.template === 'pillar') {
        document.documentElement.style.setProperty('--color-pillar', val.color);
        if (!this.$route.params.project) document.documentElement.style.setProperty('--color', val.color);
      }
      if (val.template === 'project') {
        document.documentElement.style.setProperty('--color-project', val.color);
        if (this.$route.params.project) document.documentElement.style.setProperty('--color', val.color);
      }
    },
  },
  created() {
    medusa.init();
    this.resize();
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (!isTouchDevice()) {
        document.body.classList.add('is-resize');
      }
    });

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
      }, 400),
    );

    this.$bus.$on('windowResized', this.resize);

    this.$aion.add(this.checkScroll, 'checkScroll', true);
  },
  methods: {
    resize() {
      const breakpoints = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--breakpoints')
        .replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE', is('m'));
      this.$store.commit(
        'SET_IPAD',
        is('iPad') && window.matchMedia('(orientation : landscape)').matches,
      );

      if (isIOS()) {
        if (window.innerWidth > window.innerHeight !== this.prevOrientation) {
          window.scroll(0, 0);
          this.prevOrientation = window.innerWidth > window.innerHeight;
          const vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        document.body.classList.add('is-ios');
      } else {
        document.body.classList.remove('is-ios');
      }

      document.body.classList.remove('is-resize');
    },
    checkScroll() {
      if (!this.isHomeReady) return;
      if (this.videoEmbed) {
        document.body.classList.remove('body-archive');
        return;
      }
      // if (this.$route.name === 'Home' || this.$el.dataset.back === 'true') {
      if (this.$route.name === 'Home') {
        document.body.classList.remove('body-archive');
        return;
      }

      if (this.$route.name === 'Archive') {
        return;
      }
      document.body.classList.remove('body-archive');

      const el = this.$refs.overlay;
      if (el) {
        const delta = window.innerHeight * 0.12;
        let route = '/';
        let { height } = document.body.getBoundingClientRect();
        let scroll = window.pageYOffset;
        let wHeight = window.innerHeight;

        if (
          (!this.projectInHome && this.$refs.projectRef)
          || (this.$route.params.project
            && this.$store.state.route.from
            && this.$store.state.route.from.pillar
            && !this.$store.state.route.from.project)
        ) {
          const overlayEl = this.$refs.projectRef.$el;
          height = overlayEl.querySelector('.over-main').getBoundingClientRect().height;
          scroll = overlayEl.scrollTop;

          if (!this.noContent.includes(this.$route.params.pillar)) {
            route = `/${this.$route.params.pillar}`;
          }

          if (
            this.$route.params.project
            && (!this.projectInHome
              || (this.$route.params.project
                && this.$store.state.route.from
                && this.$store.state.route.from.pillar
                && !this.$store.state.route.from.project))
          ) {
            const { from } = this.$store.state.route;
            if (from && from.params.pillar && !this.noContent.includes(from.params.pillar)) {
              route = `/${from.params.pillar}`;
            }
          }

          wHeight = 0;
        }

        if (this.archiveStatus) {
          route = '/archive';
        }

        if (Math.round(scroll + wHeight) + delta >= Math.round(height)) {
          if (!this.isAnimating) {
            this.isAnimating = true;
            this.$router.push(route);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

:root {
  --offset: calc(50 * var(--vh, 1vh));
  --color: #ffffff;
}

.hide-banner {
  opacity: 0;
}

body {
  &.no-cursor {
    * {
      cursor: none !important;
    }
  }

  &.is-resize {
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2000;
      background: black;
    }
  }

  &.fallback-fs {
    .nav,
    .main-home .navigation,
    .main-home .status [data-ui="archive"],
    [data-ui="volume"],
    [data-ui="logo"] {
      visibility: hidden !important;
      pointer-events: none !important;

      * {
        pointer-events: none !important;
      }
    }
  }
}

html.safari-fix.is-ios,
.is-ios.safari-fix body {
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100 * var(--vh, 1vh));
  overflow: hidden;
}

.main-home {
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;

  .is-ios & {
    overflow-x: hidden;
    width: 100vw;
    height: calc(100 * var(--vh, 1vh));
    // min-height: 100vh;
  }
}

.main-archive {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 2;

  &.main-no-pointer {
    pointer-events: none;
  }

  @include mq($until: m) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.main-overlay {
  &::after {
    content: "";
    height: calc(100 * var(--vh, 1vh));
    position: relative;
    pointer-events: auto;
    display: block;
    width: 100%;
    opacity: var(--opacity);
    z-index: 3;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .back {
    display: none;
  }
}

[data-back="true"] {
  .main-overlay {
    &::after {
      content: "";
      position: relative;
      height: calc(33.33 * var(--vh, 1vh));
      pointer-events: auto;
      display: block;
      width: 100%;
      opacity: var(--opacity);
      z-index: 3;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &.overlay-fixed-project {
      .main-project {
        &::after {
          height: calc(33.33 * var(--vh, 1vh));
        }
      }
    }
  }

  .back {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: calc(33.33 * var(--vh, 1vh));
    min-height: 0 !important;
    z-index: 4;
    text-align: center;

    color: var(--color, $white);

    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: map-get($spacer, s);
    }
  }
}

.main-overlay {
  position: relative;
  z-index: 3;
  backface-visibility: hidden;

  & > div {
    backface-visibility: hidden;
    min-height: calc(100 * var(--vh, 1vh));
  }

  .main-content {
    min-height: calc(100 * var(--vh, 1vh));
    backface-visibility: hidden;
  }

  &.add-before {
    &::before {
      content: "";
      position: relative;
      height: calc((100 * var(--vh, 1vh)) - var(--offset));
      pointer-events: none;
      display: block;
      width: 100%;
    }
  }

  &.overlay-fixed-pillar {
    /* &::before {
      display: none;
    } */

    .main-pillar {
      display: none;
    }
  }

  &.overlay-fixed-project {
    .main-project {
      top: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;

      &.video-embed-modal {
        @include mq(m) {
          height: 100vh;
          overflow: hidden;
        }
      }

      &::after {
        content: "";
        position: relative;
        height: calc(100 * var(--vh, 1vh));
        pointer-events: auto;
        display: block;
        width: 100%;
        opacity: var(--opacity);
        z-index: 3;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}

.main-pillar,
.main-project {
  & > div {
    min-height: calc(100 * var(--vh, 1vh));
    position: relative;

    .main-content {
      position: relative;
      background: $background;
    }
  }
}

.main-project {
  backface-visibility: hidden;
  position: relative;
  z-index: 6;
}

.disable {
  display: none;
}

/*
@include mq($until: m) {
  .item-slide[data-page="april"] {
    .cover.cover-embed {
      .media {
        .video-mobile {
          display: block !important;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100 * var(--vh, 1vh));
        }
      }
    }
  }
} */

#app {
  &.in-overlay {
    height: calc(100 * var(--vh, 1vh));
    overflow: hidden;
  }
}

.marquee-text-text {
  & + .marquee-text-text {
    margin-left: 45px;
  }
}
</style>
