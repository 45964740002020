<template>
  <div>
    <Spacer bottom="l">
      <div :class="['input-wrapper', isMobile ? 'typo--l' : 'typo--m']">
        <Spacer y="xxs">
          <input
            ref="input"
            v-model="s"
            type="text"
            placeholder="Search"
          >
          <Icon
            name="search"
            class="icon"
            :fn="submitSearch"
          />
        </Spacer>
      </div>
    </Spacer>
    <Spacer bottom="l">
      <Flex class="tags">
        <div
          v-for="tag in tags"
          :key="tag.term_id"
          :class="['tag', filterTags.includes(tag.term_id) ? 'active' : 'not-active']"
          @click="() => toggleTag(tag.term_id)"
        >
          <Spacer
            right="xs"
            bottom="s"
            :use-margin="true"
          >
            <Chip :data="{ value: tag.name }" />
          </Spacer>
        </div>
      </Flex>
    </Spacer>
    <Results
      v-if="request"
      :key="JSON.stringify(request)"
      :custom-placeholder="true"
      :data="{
        archive_type: placeholder.length > 0 && request.posts ? 'posts' : 'archive',
        archive: 'posts',
        tags: request.tags,
        search: request.search,
        posts: request.posts,
        style: {
          type: 'default',
          classes: '',
        },
        layout: [
          { media_query: 'default', layout_size: '6', layout_type: 'grid' },
          { media_query: 'm', layout_size: '3', layout_type: 'grid' },
        ]
      }"
      :callback="setReady"
      slug="archive"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import Icon from '@/components/ui/icon';
import Chip from '@/components/typo/chip';
import Results from '@/components/blocks/archive';

export default {
  name: 'Search',
  components: {
    Icon,
    Chip,
    Results,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      s: '',
      filterTags: [],
      request: null,
      debounceHandler: null,
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    if (this.placeholder.length > 0) {
      this.request = {
        posts: this.placeholder,
      };
    }
    this.debounceHandler = debounce(this.typing, 1000);
    this.$refs.input.addEventListener('input', this.debounceHandler);
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener('input', this.debounceHandler);
  },
  methods: {
    setReady() {
      if (!this.$store.state.archive.ready) this.$store.commit('SET_ARCHIVE_READY');
    },
    toggleTag(tag) {
      if (this.filterTags.includes(tag)) {
        this.filterTags = this.filterTags.filter((el) => el !== tag);
      } else {
        this.filterTags.push(tag);
      }
      this.submitSearch();
    },
    submitSearch() {
      this.request = {
        search: this.s,
        tags: this.filterTags.length > 0 ? this.filterTags : null,
        posts: this.placeholder.length > 0 && this.s === '' && this.filterTags.length === 0 ? this.placeholder : null,
        per_page: 30,
      };
    },
    typing() {
      if (this.s.length >= 3) {
        this.submitSearch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--color, $grey);
}

input {
  appearance: none;
  border: 0;
  box-shadow: none;
  font: inherit;
  background: transparent;
  width: calc(100% - 20px);
  color: var(--color, $white);

  &::placeholder {
    opacity: 0.4;
    color: var(--color, $white);
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
}

.tags {
  width: 100%;
  margin-left: 0;
  margin-right: auto;
}

.tag {
  transition: opacity 0.2s ease-out;

  ::v-deep .chip {
    background: var(--background, $black);
    color: var(--color, $white);
    border-color: var(--color, $white);
  }

  &.not-active {
    opacity: 0.3;

    &:hover {
      opacity: 0.7;
    }
  }

  &.active {
    opacity: 1;

    ::v-deep .chip {
      background: var(--color, $white);
      color: var(--background, $black);
    }
  }
}
</style>
