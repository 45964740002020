import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      lazyLoadImgs: [],
      lazyArchive: false,
      lazySlug: this.slug,
    };
  },
  computed: {
    ...mapGetters(['transition', 'isHomeReady']),
  },
  watch: {
    isHomeReady(val) {
      if (val) this.lazyObserve();
    },
    $route() {
      this.lazyObserve();
    },
  },
  mounted() {
    if (this.isHomeReady) {
      this.lazyObserve();
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes(`lazy-${this.lazySlug}`)) {
      medusa.ref.removeTarget(`lazy-${this.lazySlug}`);
    }
  },
  methods: {
    loadImage(img) {
      return new Promise((resolve, reject) => {
        img.addEventListener('load', () => resolve());
        img.addEventListener('error', () => reject());
      });
    },
    lazyObserve() {
      if (medusa.ref.idList.includes(`lazy-${this.lazySlug}`)) {
        this.$nextTick(() => {
          this.lazyLoadImgs = Array.from(this.$el.querySelectorAll('figure.picture[data-lazy="false"] img.image'));
          medusa.ref.pushToTarget(`lazy-${this.lazySlug}`, this.lazyLoadImgs);
        });
      } else {
        this.$nextTick(() => {
          this.lazyLoadImgs = Array.from(this.$el.querySelectorAll('figure.picture[data-lazy="false"] img.image'));
          medusa.ref.addTarget({
            id: `lazy-${this.lazySlug}`,
            container: this.$el,
            threshold: 0.0,
            nodes: this.lazyLoadImgs,
            mode: 'once',
            callback: this.decode,
            autoremove: false,
          });
        });
      }
    },
    decode(entry) {
      const { target, isIntersecting } = entry;
      if (isIntersecting || window.innerHeight > this.$el.offsetHeight) {
        const sources = Array.from(target.parentNode.querySelectorAll('source'));
        if (sources) {
          sources.forEach((s) => {
            if (s.dataset.srcset) {
              s.srcset = s.dataset.srcset;
            }
          });
        }

        target.src = target.dataset.src;
        this.loadImage(target)
          .then(() => {
            target.closest('figure').dataset.lazy = true;
          })
          .catch(() => {
            target.closest('figure').dataset.lazy = true;
          });
      }
    },
  },
};
