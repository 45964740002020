<template>
  <Spacer
    class="chat"
    :x="{ default: 'xs', m: '0' }"
  >
    <div
      v-for="(msg, index) in filteredMessages"
      :key="msg.id"
      ref="messages"
      :class="['msg-wrapper', msg.bot ? 'msg-wrapper-bot' : 'msg-wrapper-human']"
    >
      <Spacer y="xs">
        <transition
          appear
          @enter="enter"
        >
          <div>
            <Flex
              v-if="!msg.input && !msg.btn"
              :align="msg.bot ? 'h-left' : 'h-right'"
            >
              <div
                :class="['msg', 'black-ext', 'typo--s', msg.bot ? 'msg-primary' : 'msg-default', index === 0 && !started ? 'msg-pointer' : false]"
                @click.stop="index === 0 && !started ? startChat() : false"
              >
                <Spacer template="chip">
                  <span class="loading">
                    <div class="dot-typing" />
                  </span>
                  <span
                    class="text"
                    :style="{ display: current <= index ? 'none' : false }"
                    v-html="msg.value"
                  />
                </Spacer>
              </div>
            </Flex>
            <div
              v-else-if="msg.input"
              ref="input"
              :class="['input-wrapper', 'typo--s', 'black-ext', email !== '' ? 'input-active' : false]"
            >
              <Spacer template="chip">
                <input
                  ref="input"
                  v-model="email"
                  type="text"
                  :placeholder="msg.value"
                >
                <Icon
                  name="send"
                  class="icon"
                  :fn="sendMail"
                />
              </Spacer>
            </div>
            <Flex
              v-else-if="msg.btn"
              class="msg-wrapper"
              align="h-right"
            >
              <Flex>
                <div
                  class="msg typo--s msg-default msg-current"
                  @click.stop="choice('yes')"
                >
                  <Spacer template="chip">
                    <span
                      class="text"
                      v-html="'Yes!'"
                    />
                  </Spacer>
                </div>
                <div
                  class="msg typo--s msg-default msg-current"
                  @click.stop="choice('no')"
                >
                  <Spacer template="chip">
                    <span
                      class="text"
                      v-html="'No'"
                    />
                  </Spacer>
                </div>
              </Flex>
            </Flex>
          </div>
        </transition>
      </Spacer>
    </div>
  </Spacer>
</template>

<script>
import axios from 'axios';
import gsap from 'gsap';

import { validateEmail } from '@/assets/js/utils';
import Icon from '@/components/ui/icon';

export default {
  name: 'Chat',
  components: {
    Icon,
  },
  data() {
    return {
      email: '',
      current: -1,
      currentContinue: -1,
      interval: null,
      intervalContinue: null,
      intervalEmail: null,
      messagesChat: [],
      started: false,
      isResetting: false,
      messages: {
        start: [
          {
            id: 'hey',
            value: 'Hey',
            bot: true,
            show: true,
          },
          {
            id: 'get-in-touch',
            value: 'Do you want to get in touch?',
            bot: true,
            show: false,
          },
          {
            id: 'yes-no',
            bot: false,
            show: false,
            btn: true,
          },
        ],
        yes: [
          {
            id: 'sure',
            value: 'Sure, what should I do?',
            bot: false,
            show: false,
          },
          {
            id: 'type-email',
            value: 'Type your e-mail and <br>we will contact you soon!',
            bot: true,
            show: false,
          },
          {
            id: 'email',
            value: 'Your e-mail...',
            input: true,
            show: false,
          },
          {
            id: 'sent-email',
            value: null,
            bot: false,
            show: false,
          },
          {
            id: 'thx-msg',
            value: 'Thanks for reaching out!<br> We will be in touch and contact you soon!',
            bot: true,
            show: false,
          },
        ],
        no: [
          {
            id: 'no-thx',
            value: 'No, thank you.',
            bot: false,
            show: false,
          }, {
            id: 'follow-instagram',
            value: 'No problem, meanwhile you can<br> follow us on Instagram! ',
            bot: true,
            show: false,
          },
          {
            id: 'instagram',
            value: '<a href="https://www.instagram.com/studio.april/" target="_blank">@studio.april</a>',
            bot: true,
            show: false,
          },
        ],
      },
    };
  },
  computed: {
    filteredMessages() {
      return this.messagesChat.filter((el) => el.show);
    },
  },
  mounted() {
    this.initChat();

    setTimeout(() => {
      if (!this.started) {
        this.startChat();
      }
    }, 3000);

    document.body.addEventListener('click', this.resetChat);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.resetChat);
  },
  methods: {
    leave(el, done) {
      done();
    },
    enter(el, done) {
      gsap.set(el, {
        y: 30,
        autoAlpha: 0,
      });
      gsap.to(el, {
        y: 0,
        autoAlpha: 1,
        duration: 0.4,
        ease: 'power2.out',
        onComplete: () => {
          done();
          setTimeout(() => {
            this.switchType(el);
          }, 400);
        },
      });
    },
    choice(val) {
      this.step = val;
      this.$refs.messages[this.messagesChat.length - 1].remove();
      this.currentContinue += 1;
      this.current += 1;

      this.$nextTick(() => {
        this.messagesChat.push(this.messages[val][this.currentContinue]);
        this.messagesChat[this.current].show = true;
        this.continueChat();
      });
    },
    sendMail() {
      if (validateEmail(this.email)) {
        this.$refs.messages[this.messagesChat.length - 1].classList.remove('is-error');
        this.$refs.messages[this.messagesChat.length - 1].remove();
        this.messages.yes[3].value = this.email;
        this.currentContinue += 1;
        this.current += 1;

        // eslint-disable-next-line no-undef
        const { url } = __VUE_WORDPRESS__.routing;
        axios.get(`${url}/wp-content/themes/gds-vue/functions/mailchimp/send.php`, {
          params: {
            email: this.email,
          },
        });

        this.$nextTick(() => {
          this.messagesChat.push(this.messages.yes[this.currentContinue]);
          this.messagesChat[this.current].show = true;
          this.emailChat();
        });
      } else {
        this.$refs.messages[this.messagesChat.length - 1].classList.add('is-error');

        setTimeout(() => {
          this.$refs.messages[this.messagesChat.length - 1].classList.remove('is-error');
        }, 1000);
      }
    },
    switchType(el) {
      const loading = el.querySelector('.loading');
      const text = el.querySelector('.text');

      if (loading) {
        gsap.set(loading, {
          display: 'none',
        });
      }
      if (text) {
        gsap.set(text, {
          clearProps: 'all',
        });
      }
    },
    initChat() {
      this.current = 0;
      this.isResetting = false;
      this.messagesChat.push(this.messages.start[0]);
    },
    startChat() {
      this.started = true;
      if (this.current === 0) {
        this.current += 1;
        this.messagesChat.push(this.messages.start[this.current]);
        this.messagesChat[this.current].show = true;
      }

      this.interval = setInterval(() => {
        if (this.current >= 2) {
          clearInterval(this.interval);
        } else {
          this.current += 1;
          this.messagesChat.push(this.messages.start[this.current]);
          this.messagesChat[this.current].show = true;
        }
      }, 1800);
    },
    continueChat() {
      this.intervalContinue = setInterval(() => {
        if (this.currentContinue === 2) {
          clearInterval(this.intervalContinue);
        } else {
          this.current += 1;
          this.currentContinue += 1;
          this.messagesChat.push(this.messages[this.step][this.currentContinue]);
          this.messagesChat[this.current].show = true;
        }
      }, 1800);
    },
    emailChat() {
      this.intervalEmail = setInterval(() => {
        if (this.currentContinue === 4) {
          clearInterval(this.intervalEmail);
        } else {
          this.current += 1;
          this.currentContinue += 1;
          this.messagesChat.push(this.messages.yes[this.currentContinue]);
          this.messagesChat[this.current].show = true;
        }
      }, 1800);
    },
    resetChat(e) {
      if (e.target !== this.$el && !this.$el.contains(e.target)) {
        if (!this.started || this.isResetting) return;

        this.isResetting = true;
        this.email = '';
        this.current = -1;
        this.currentContinue = -1;

        if (this.interval) clearInterval(this.interval);
        if (this.intervalContinue) clearInterval(this.intervalContinue);
        if (this.intervalEmail) clearInterval(this.intervalEmail);

        this.interval = null;
        this.intervalContinue = null;
        this.intervalEmail = null;
        this.messagesChat = [];
        this.started = false;

        this.$nextTick(() => {
          this.initChat();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  max-width: 350px;

  @include mq($until: m) {
    margin: 0 auto;
    max-width: none;
  }
}

.msg-wrapper-bot {
  &+.msg-wrapper-bot {
    margin-top: -10px;
  }
}

.msg-wrapper-human {
  &+.msg-wrapper-human {
    margin-top: -10px;
  }
}

.msg {
  border-radius: 15px;
  min-width: 50px;
  display: inline-block;

  &-right {
    margin-left: auto;
    margin-right: 0;
  }

  &-default {
    background: $white;
    color: $black;
    border: 1px solid $white;
  }

  &-primary {
    background: $primary-color;
    color: $black;
    border: 1px solid $primary-color;
  }

  &-pointer {
    cursor: pointer;
  }

  &-current {
    cursor: pointer;
    background: $white;
    color: $black;
    text-align: center;

    &:hover {
      background: $black;
      color: $white;
    }

    &+.msg-current {
      margin-left: $unit / 4;
    }
  }
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  border: 1px solid $grey;
  background: $grey;
  color: $white;

  input {
    appearance: none;
    border: 0;
    box-shadow: none;
    font: inherit;
    width: 100%;
    background: transparent;
    color: $white;

    &::placeholder {
      color: rgba($white, 0.4);
    }

    width: calc(100% - 20px);

  }

  .icon {
    position: absolute;
    top: 50%;
    right: $unit * 0.5;
    transform: translate3d(0, -50%, 0);
    opacity: 0.5;
  }

  &.input-active {
    border-color: $black;

    .icon {
      opacity: 1;
    }
  }
}

.is-error {
  pointer-events: none;

  .input-wrapper {
    border: 1px solid red;
    background: red;
  }
}

.loading {
  display: inline-block;
  position: relative;
  left: 8px;
  height: 10px;
}

.dot-typing {
  position: relative;
  left: -9999px;
  top: 2px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.7;
  box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  }

  16.667% {
    box-shadow: 9990px -4px 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  }

  33.333% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  }

  50% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px -4px 0 0 currentColor, 10009px 0 0 0 currentColor;
  }

  66.667% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  }

  83.333% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px -4px 0 0 currentColor;
  }

  100% {
    box-shadow: 9990px 0 0 0 currentColor, 9999px 0 0 0 currentColor, 10009px 0 0 0 currentColor;
  }
}
</style>
