import drop from 'lodash.drop';

import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      placeholder: 'Loading',
      custom: [],
      first: true,
      offset: 0,
      lazyArchiveSlug: this.slug,
      customGridSignal: false,
      preloadItems: 4,
    };
  },
  mounted() {
    this.setRequest();
    if (this.request) {
      if (this.data.archive_type === 'posts') {
        this.items = this.data.posts;

        this.callback();
        this.lazyLoadNewItems();
      } else {
        this.loadItems().then(() => {
          if (this.data.infinite_scroll && this.data.archive_type !== 'posts') {
            this.$nextTick(this.setInfiniteScroll);
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
    if (medusa.ref && medusa.ref.idList.includes(`lazy-archive-${this.lazyArchiveSlug}`)) {
      medusa.ref.removeTarget(`lazy-archive-${this.lazyArchiveSlug}`);
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
        if (this.data.style.classes.includes('grid-fuoritempo')) {
          this.custom = this.setCustomGrid(this.items);
        }
      }

      this.first = false;

      this.callback();
      this.lazyLoadNewItems();

      return items;
    },
    setRequest() {
      let postType = null;
      const category = {};
      let include = [];
      let slugArray = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const { archive, archive_category, tags } = this.data;
        postType = archive;
        if (archive_category) {
          category[archive_category.taxonomy] = archive_category.term_id;
        }
        if (tags) {
          category.tags = tags;
        }
      } else if (this.type === 'posts') {
        postType = `${this.data[this.type][0].post_type}s`;
        slugArray = this.data[this.type].map((item) => item.post_name);
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      const { exclude } = this.data;

      const slug = slugArray.length > 0 ? this.data.infinite_scroll ? drop(slugArray, this.offset) : slugArray : null;
      const offset = this.type !== 'posts' ? this.offset : 0;

      this.preloadItems = slugArray.length >= 4 ? slugArray.length : 4;

      this.request = {
        type: postType,
        params: {
          ...category,
          slug,
          search: (typeof this.data.search !== 'undefined') ? this.data.search : null,
          include: this.data.include && this.data.include.length > 0 ? this.data.include : include.length > 0 ? include : null,
          exclude: exclude && exclude.length > 0 ? exclude : null,
          per_page: this.data.infinite_scroll ? parseInt(this.data.posts_per_page, 10) : parseInt(this.data.max_posts, 10) || 30,
          offset,
          order: slugArray.length > 0 ? 'desc' : 'asc',
          orderby: this.data.orderBy ? this.data.orderBy : slugArray.length > 0 ? 'include_slugs' : this.type === 'taxonomy' ? 'id' : 'menu_order',
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        container: this.$el,
        threshold: 0.1,
        nodes: '.infinite',
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      this.offset += parseInt(this.data.posts_per_page, 10);
      this.setRequest();
      if (this.request) {
        this.loadItems().then((newItems) => {
          this.lazyLoadNewItems();

          if (window.innerHeight > this.$el.offsetHeight) {
            // this.requestOffset();
          }

          if (newItems.length === 0) medusa.ref.removeTarget('infinite-scroll');
        });
      }
    },
    lazyLoadNewItems() {
      this.$nextTick(() => {
        const lazyImgs = Array.from(this.$el.querySelectorAll('figure.picture[data-lazy="false"] img.image'));
        if (medusa.ref.idList.includes(`lazy-archive-${this.lazyArchiveSlug}`)) {
          medusa.ref.pushToTarget(`lazy-archive-${this.lazyArchiveSlug}`, lazyImgs);
        } else {
          medusa.ref.addTarget({
            id: `lazy-archive-${this.lazyArchiveSlug}`,
            container: this.$el,
            threshold: 0.0,
            nodes: lazyImgs,
            mode: 'once',
            callback: this.decodeArchive,
            autoremove: false,
          });
        }
      });
    },
    decodeArchive(entry) {
      const { target, isIntersecting } = entry;
      if (isIntersecting) {
        const sources = Array.from(target.parentNode.querySelectorAll('source'));
        if (sources) {
          sources.forEach((s) => {
            if (s.dataset.srcset) {
              s.srcset = s.dataset.srcset;
            }
          });
        }

        target.src = target.dataset.src;
        target.decode()
          .then(() => {
            target.closest('figure').dataset.lazy = true;
          })
          .catch(() => {
            target.closest('figure').dataset.lazy = true;
          });
      }
    },
    setCustomGrid(items) {
      return [items.slice(0, 5), items.slice(5)];
    },
  },
};
