<template>
  <div>
    <template v-if="currentTemplate === 'grid'">
      <div
        :class="[
          'grid',
          ...gridCol,
          ...extraClass,
        ]"
      >
        <slot />
      </div>
    </template>
    <template v-if="currentTemplate === 'carousel'">
      <Carousel :col="col">
        <slot />
      </Carousel>
    </template>
  </div>
</template>

<script>
import { is } from '@/assets/js/utils';

export default {
  name: 'Grid',
  props: {
    col: {
      type: [Object, String],
      default: '',
    },
    template: {
      type: [String, Object],
      default: 'grid',
    },
    extraClass: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      currentTemplate: 'grid',
      stopSignal: false,
    };
  },
  computed: {
    gridCol() {
      const { col } = this;
      const cols = [];
      if (typeof col === 'string' && col !== '') {
        cols.push(`grid-${col}`);
      } else if (typeof col === 'object') {
        Object.keys(col).forEach((key) => {
          if (key !== 'default') {
            cols.push(`grid-${key}-${col[key]}`);
          } else {
            cols.push(`grid-${col[key]}`);
          }
        });
      }

      return cols;
    },
  },
  mounted() {
    if (typeof this.template === 'object') {
      this.watchLayout();
      this.$bus.$on('windowResized', this.watchLayout);
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.watchLayout);
  },
  methods: {
    watchLayout() {
      this.stopSignal = false;
      Object.keys(this.template).forEach((key, index) => {
        if (is(key) && !this.stopSignal) {
          this.stopSignal = true;
          this.currentTemplate = this.template[key];
        } else if (!this.stopSignal && index === (Object.keys(this.template).length - 1)) {
          this.currentTemplate = this.template[key];
        }
      });
    },
  },
};
</script>

<style>

</style>
