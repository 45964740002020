<template>
  <div
    :class="[
      'video-wrapper',
      fallbackFs ? 'video-wrapper-fallback' : false,
      data.cover ? 'video-wrapper-cover' : false,
      { 'video-half': data.section_layout === 'half-half' }
    ]"
    :style="data.cover && !isMobile && !isTouch
      ? {
        '--iframe-height': `${100 * (video.height / video.width)
        }vw`,
        '--iframe-min-height': `${100 * (100 / (100 * (video.height / video.width)))
        }vh`,
      }
      : false
    "
  >
    <div class="video-player-wrapper">
      <div
        v-if="video"
        :class="[
          'video-player',
          vh ? 'full-height' : false,
          data.embedMode || data.vimeo_full ? 'embed' : false,
          data.vimeo_full && !data.cover ? 'full' : false,
        ]"
        :data-embed="data.embedMode ? 'true' : 'false'"
        :data-cursor="videoCursor ? videoCursor : null"
        @click="!videoInline ? toggleFullVideo() : null"
      >
        <div
          class="wrapper"
          :style="{ paddingBottom: !vh ? `${ratio}%` : false }"
        >
          <div
            v-if="!isMobile &&
              !isTouch &&
              ((data.embedMode && data.vimeo_full && data.cover) ||
                (data.vimeo_full && !data.cover)) &&
              id
            "
            ref="plyr"
            class="plyr-app"
            data-plyr-provider="vimeo"
            :data-plyr-embed-id="id"
          />

          <video
            v-if="!(
              (data.embedMode && data.vimeo_full && data.cover) ||
              (data.vimeo_full && !data.cover)
            )
            "
            ref="plyr"
            class="plyr-app"
            preload="metadata"
            playsinline
            :loop="data.loop || videoInline"
            :autoplay="videoInline ||
              (!!data.loop && !data.embedMode && data.cover)
              ? 'autoplay'
              : false
            "
            :muted="!audio || videoInline ? 'muted' : false"
          >
            <source
              :src="src"
              type="video/mp4"
            >
          </video>
        </div>
      </div>

      <Cta
        v-if="(!data.cover &&
          data.vimeo_full &&
          (isMobile || isTouch || isTouch)) ||
          fallbackFs
        "
        v-show="!showUi || plyr.paused"
        class="cover-cta"
        :data="{ title: 'Play full video', size: 's', fn: () => { } }"
      />

      <video
        v-if="(isMobile || isTouch) &&
          ((data.embedMode && data.vimeo_full && data.cover) ||
            (data.vimeo_full && !data.cover))
        "
        v-show="fullVideo || !data.cover"
        ref="videoMobile"
        preload="metadata"
        :class="[
          'video-mobile',
          !data.cover ? 'video-mobile-visible' : false,
          fallbackFs ? 'video-fallback' : false,
        ]"
        :poster="!data.cover || fallbackFs
          ? video.pictures
            ? video.pictures.sizes[video.pictures.sizes.length - 1]
              .link
            : null
          : null
        "
      >
        <source
          :src="src"
          type="video/mp4"
        >
      </video>

      <div
        v-if="fallbackFs"
        class="close"
      >
        <Icon
          name="close"
          :fn="closeFallback"
        />
      </div>

      <div
        v-if="!data.cover && data.vimeo_full && showUi"
        class="video-ui"
      >
        <div
          ref="status"
          :class="['status']"
          @click="setSeek"
        >
          <div class="bar">
            <div
              class="progress"
              :style="{ transform: `scaleX(${progress})` }"
            />
          </div>
        </div>
        <div class="ui-bottom">
          <Spacer template="video">
            <div class="ui-bottom-wrapper">
              <div class="time">
                {{ millisToMinutesAndSeconds(current) }}/<span>{{
                  millisToMinutesAndSeconds(plyr.duration)
                }}</span>
              </div>
            </div>
          </Spacer>
        </div>
      </div>
    </div>

    <figcaption v-if="data.caption && !data.cover">
      <Spacer y="xs">
        <Label :data="{ value: data.caption, tag: 'h3', size: 's' }" />
      </Spacer>
    </figcaption>
  </div>
</template>

<script>
import Plyr from 'plyr';
import orderBy from 'lodash.orderby';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import 'plyr/dist/plyr.css';

import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';
import { isIOS, millisToMinutesAndSeconds } from '@/assets/js/utils';

export default {
  name: 'VideoPlayer',
  components: {
    Label,
    Cta,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    vh: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      plyr: null,
      src: null,
      playing: false,
      loading: false,
      fullVideo: false,
      videoCursor: this.data.embedMode
        ? 'Play full video'
        : this.data.vimeo_full && !this.data.cover
          ? 'Play'
          : this.cursor !== ''
            ? this.cursor
            : false,
      prevVideoCursor: null,
      quality: [],
      video: this.data.cover
        ? !this.data.embedMode
          ? window.innerWidth < 1000 && this.data.vimeo_teaser_mobile
            ? this.data.vimeo_teaser_mobile
            : this.data.vimeo_teaser
          : window.innerWidth < 1000 && this.data.vimeo_full_mobile
            ? this.data.vimeo_full_mobile
            : this.data.vimeo_full
        : window.innerWidth < 1000 && this.data.vimeo_full_mobile
          ? this.data.vimeo_full_mobile
          : this.data.vimeo_full
            ? this.data.vimeo_full
            : window.innerWidth < 1000 && this.data.vimeo_teaser_mobile
              ? this.data.vimeo_teaser_mobile
              : this.data.vimeo_teaser,
      volume: {
        value: 0,
        icon: 'volume_on',
        val: 1,
      },
      showUi: false,
      progress: 0,
      current: 0,
      buffered: true,
      millisToMinutesAndSeconds,
      fullscreen: false,
      fallbackFs: false,
      videoInline:
        !this.data.cover
        && this.data.vimeo_teaser
        && !this.data.vimeo_full,
    };
  },
  computed: {
    ratio() {
      return this.video
        ? (this.video.height / this.video.width) * 100
        : null;
    },
    id() {
      if (!this.video?.uri) return false;

      const uri = this.video.uri.split('/');
      return uri[uri.length - 1];
    },
    ...mapGetters([
      'homeVideo',
      'audio',
      'isMobile',
      'isTouch',
      'videoEmbed',
      'currentVideo',
      'isTouch',
    ]),
  },
  watch: {
    audio(val) {
      if (this.plyr) {
        this.plyr.muted = !val;
      }
    },
  },
  mounted() {
    if (this.video && this.$refs.plyr) {
      this.plyr = new Plyr(this.$refs.plyr, {
        controls: false,
        autoplay:
          this.videoInline
          || (!!this.data.loop
            && !this.data.embedMode
            && this.data.cover),
        muted: !this.audio || this.videoInline,
        storage: { enabled: false },
        clickToPlay: false,
      });

      // if (this.video.files) this.plyr.source = this.setSource();
      this.source();
      this.plyr.embedMode = this.data.embedMode;
      if (!this.videoInline) {
        if (this.data.home && !this.data.embedMode) {
          this.$store.commit('SET_HOME_VIDEOS', this.plyr);
        } else if (!this.data.embedMode) {
          this.$store.commit('SET_VIDEOS', this.plyr);
        } else {
          this.$bus.$on('closeVideo', this.closeFullVideo);
        }

        this.plyr.on('play', this.checkVideo);
        this.plyr.on('pause', this.checkPause);
      }
    } else if (this.video) {
      this.source();
    }

    this.$bus.$on('windowResized', this.checkVideoSrc);
  },
  beforeDestroy() {
    if (this.data.embedMode) {
      this.closeFullVideo();
      this.$bus.$off('closeVideo', this.closeFullVideo);
    }
    if (this.plyr && !this.videoInline) {
      this.plyr.off('play', this.checkVideo);
      this.plyr.off('pause', this.checkPause);
    }
  },
  methods: {
    checkVideoSrc() {
      this.video = this.data.cover
        ? !this.data.embedMode
          ? window.innerWidth < 1000 && this.data.vimeo_teaser_mobile
            ? this.data.vimeo_teaser_mobile
            : this.data.vimeo_teaser
          : window.innerWidth < 1000 && this.data.vimeo_full_mobile
            ? this.data.vimeo_full_mobile
            : this.data.vimeo_full
        : window.innerWidth < 1000 && this.data.vimeo_full_mobile
          ? this.data.vimeo_full_mobile
          : this.data.vimeo_full
            ? this.data.vimeo_full
            : window.innerWidth < 1000 && this.data.vimeo_teaser_mobile
              ? this.data.vimeo_teaser_mobile
              : this.data.vimeo_teaser;

      this.source();
    },
    checkVideo() {
      if (this.currentVideo && this.currentVideo.id === this.plyr.id) return;
      if (this.currentVideo && this.currentVideo.playing) {
        this.currentVideo.pause();
      }

      this.$store.commit('SET_CURRENT_VIDEO', this.plyr);
    },
    checkPause() {
      if (this.currentVideo.id !== this.plyr.id) {
        if (this.videoCursor) {
          this.prevVideoCursor = this.videoCursor;
          if (this.fullVideo) this.fullVideo = false;
        }
      }
    },
    // setSource() {
    //   const source = {
    //     type: 'video',
    //     sources: [],
    //     poster: this.video.pictures ? this.video.pictures.sizes[5].link : null,
    //   };

    //   if (!this.video.files) {
    //     return null;
    //   }

    //   const dateFiles = orderBy(this.video.files, ['width'], ['asc']);
    //   const files = dateFiles.filter((file) => (file.width <= window.innerWidth));
    //   const file = files.length > 0 ? files[files.length - 1] : dateFiles[0];

    //   source.sources.push({
    //     src: file.link,
    //     type: file.type,
    //     size: file.width,
    //   });

    //   return source;
    // },
    source() {
      if (!this.video.files) {
        this.src = null;
      }

      const dateFiles = orderBy(
        this.video.files.filter((video) => video.width),
        ['width'],
        ['desc'],
      );
      const files = dateFiles.filter(
        (file) => file.quality === 'hd' && window.innerWidth < 1000,
      );
      const file = files.length > 0 ? files[files.length - 1] : dateFiles[0];
      this.src = file.link;
    },
    exitFullScreen() {
      if (!isIOS()) {
        if (!document.fullscreenElement) {
          this.closeFullVideo();
          this.fullscreen = false;
          this.fullVideo = false;
          this.$refs.videoMobile.pause();
          if (this.data.cover) {
            this.$refs.videoMobile.style.display = 'none';
          }
          this.$refs.videoMobile.removeEventListener(
            'fullscreenchange',
            this.exitFullScreen,
          );
          this.fallbackFs = false;
          document.body.classList.remove('fallback-fs');
          if (this.currentVideo && this.currentVideo.paused) this.currentVideo.play();
        }
      } else {
        this.closeFullVideo();
        this.fullscreen = false;
        this.fullVideo = false;
        this.$refs.videoMobile.removeEventListener(
          'webkitendfullscreen',
          this.exitFullScreen,
        );
        this.fallbackFs = false;
        document.body.classList.remove('fallback-fs');

        if (this.currentVideo && this.currentVideo.paused) this.currentVideo.play();
      }
    },
    closeFallback() {
      this.closeFullVideo();
      this.fullscreen = false;
      this.fallbackFs = false;
      this.fullVideo = false;
      document.body.classList.remove('fallback-fs');
    },
    toggleFullVideo() {
      if (!this.data.embedMode && this.$route.name === 'Home') return;
      this.fullVideo = !this.fullVideo;

      if (this.isMobile || this.isTouch) {
        if (this.fullVideo) {
          if (this.currentVideo && this.currentVideo.playing) this.currentVideo.pause();

          if (!isIOS()) {
            this.$refs.videoMobile
              .requestFullscreen()
              .then(() => {
                this.$refs.videoMobile.play();
                this.$refs.videoMobile.style.display = 'block';
                this.$refs.videoMobile.addEventListener(
                  'fullscreenchange',
                  this.exitFullScreen,
                );
              })
              .catch(() => {
                this.fallbackFs = true;
                document.body.classList.add('fallback-fs');
                this.fullVideo = false;
              });
          } else {
            this.$refs.videoMobile
              .play()
              .then(() => {
                this.$refs.videoMobile.webkitEnterFullscreen();
                this.$refs.videoMobile.addEventListener(
                  'webkitendfullscreen',
                  this.exitFullScreen,
                );
              })
              .catch(() => {
                this.fallbackFs = true;
                document.body.classList.add('fallback-fs');
                this.fullVideo = false;
              });
          }
        }
      } else {
        if (this.data.cover) {
          this.$parent.$el.parentNode.classList.add(
            'cover-embed-visible',
          );
        }

        if (this.fullVideo) {
          const container = document.querySelector('.main-project');
          if (this.data.cover) {
            if (container) container.classList.add('video-embed-modal');
            disableBodyScroll(document.querySelector('.disable'));
            this.$store.commit('SET_UI', {
              status: 'video',
              show: false,
            });
          } else {
            this.showUi = true;
            this.$aion.add(this.setProgress, `setProgressVideo-${this.plyr.id}`);
          }
          if (this.data.cover) {
            this.$store.commit('SET_VIDEO_EMBED', this.plyr);
            gsap.to([window, container], {
              scrollTo: this.$el,
              duration: 0.5,
              ease: 'power2.out',
              onComplete: () => {
                if (this.plyr.paused) {
                  this.plyr.play().then(() => { });
                }
                this.prevVideoCursor = this.videoCursor;
                this.videoCursor = 'Pause';

                const cover = this.$parent.$parent.$refs?.videoCover?.$refs?.media
                  ?.plyr;
                if (cover) {
                  if (cover.playing) cover.pause();
                } else if (
                  this.homeVideo.id
                  && this.homeVideo.playing
                ) this.homeVideo.pause();
              },
            });
          } else {
            if (this.plyr.paused) {
              this.plyr.play().then(() => { });
            }
            if (this.homeVideo.id && this.homeVideo.playing) this.homeVideo.pause();
            this.prevVideoCursor = this.videoCursor;
            this.videoCursor = 'Pause';

            const cover = this.$parent.$parent.$refs?.videoCover?.$refs?.media
              ?.plyr;
            if (cover) {
              if (cover.playing) cover.pause();
            } else if (this.homeVideo.id && this.homeVideo.playing) {
              this.homeVideo.pause();
            }
          }
        } else {
          if (this.plyr.playing) this.plyr.pause();
          this.prevVideoCursor = this.videoCursor;
          this.videoCursor = 'Play';
          this.$aion.remove(`setProgressVideo-${this.plyr.id}`);
          if (this.data.cover) {
            this.showUi = false;
          }
        }
      }
    },
    closeFullVideo() {
      if (this.videoEmbed?.id !== this.plyr?.id) return;
      this.fullVideo = false;
      document.body.classList.remove('is-fullscreen');
      this.$parent.$el.parentNode.classList.remove(
        'cover-embed-visible',
      );
      this.prevVideoCursor = this.videoCursor;
      this.videoCursor = 'Play full video';
      this.$store.commit('SET_UI', { status: 'video', show: true });
      if (this.data.cover) {
        this.$store.commit('SET_VIDEO_EMBED', null);
      }
      if (this.plyr) {
        this.plyr.pause();
        this.plyr.currentTime = 0;
      }

      const cover = this.$parent.$parent.$refs?.videoCover?.$refs?.media?.plyr;
      if (cover) {
        if (cover.paused) cover.play();
      } else if (this.homeVideo) {
        if (this.homeVideo.id && this.homeVideo.paused) this.homeVideo.play();
      }

      clearAllBodyScrollLocks();
      const container = document.querySelector('.main-project');
      if (container) container.classList.remove('video-embed-modal');
    },
    setProgress() {
      if (this.video) {
        this.current = this.plyr.currentTime;
        this.progress = Math.min(
          1,
          this.plyr.currentTime / this.video.duration,
        );
      }
    },
    setSeek(e) {
      if (this.plyr) {
        const value = (e.layerX
          / this.$refs.status.getBoundingClientRect().width)
          * this.plyr.duration;
        // this.videoEmbed.currentTime = value;
        if (value > this.plyr.currentTime) {
          const seek = value - this.plyr.currentTime;
          this.plyr.forward(seek);
        } else {
          const seek = this.plyr.currentTime - value;
          this.plyr.rewind(seek);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  color: $white;
  padding: 24px;
}

.video-mobile {
  pointer-events: none;

  &-visible {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.video-fallback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block !important;
  }
}

.video-wrapper-fallback {
  opacity: 1 !important;
  background: black;
  z-index: 300;
}

.video-wrapper,
.video-player-wrapper {
  position: relative;
  height: 100%;
}

.video-player {
  position: relative;
  width: 100%;
  pointer-events: none;
  cursor: none;
  height: 100%;

  * {
    pointer-events: none;
  }

  &[data-cursor] {
    pointer-events: auto;
  }

  &.embed {
    pointer-events: auto;
  }

  &.full {}

  .wrapper {
    position: relative;
    width: 100%;
    height: 0;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.full-height {
    .wrapper {
      height: calc(100 * var(--vh, 1vh));
      // height: -webkit-fill-available;
    }
  }
}

.cover-cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 5;
  color: $white;
  pointer-events: none;
  cursor: pointer;
}

.video-ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .ui-top {
    position: absolute;
    right: 0;
    top: 12px;

    @include mq(m) {
      top: 24px;
    }
  }

  .ui-bottom {
    position: absolute;
    left: 50%;
    z-index: 4;
    color: var(--color, $white);
    transform: translate3d(-50%, 0, 0);

    top: 12px;

    @include mq(m) {
      top: 24px;
    }

    span {
      opacity: 0.7;
    }

    .time {
      align-self: center;
    }

    pointer-events: none;
  }

  .status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 10;
    transition: transform 0.2s ease-out;
    pointer-events: auto;
    transform-origin: 50% 0%;
  }

  .bar {
    position: relative;
    height: $unit * 0.4;
    background: rgba($white, 0.3);
  }

  .progress {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: 0% 50%;
    background: var(--color, $white);
  }
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .plyr__video-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);

    @include mq($from: iPad) {
      top: 0;
      transform: none;
      height: 100% !important;
      padding-bottom: 0 !important;
    }
  }

  .plyr__video-embed__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @include mq($from: iPad) {
      height: 100% !important;
      padding-bottom: 0 !important;
      transform: none !important;
      /* iframe {
        width: 100vw;
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      } */
    }
  }

  iframe {
    pointer-events: none !important;
  }

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type='range'] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__poster {
    transition-duration: 0s;
    background-size: cover;
    background-color: transparent;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}

.plyr--stopped {
  .plyr__poster {
    transition-delay: 0.5s;
    opacity: 1;
  }
}

.plyr--playing {
  .plyr__poster {
    opacity: 0;
  }
}

.video-wrapper-cover {
  .plyr {
    .plyr__video-embed__container {
      @include mq($from: iPad) {
        iframe {
          width: 100vw;
          height: var(--iframe-height, 56.25vw);
          min-height: 100vh;
          min-width: var(--iframe-min-height, 177.77vh);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.video-half {
  .video-player .wrapper {
    overflow: hidden;

    .plyr {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
