import Vue from 'vue';
import Aion from '@adoratorio/aion';

const aion = new Aion({
  autostop: false,
});

aion.install = () => {
  aion.start();
  Vue.prototype.$aion = aion;
};

Vue.use(aion);
