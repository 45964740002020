<template>
  <div
    class="cta"
    :class="[data.style ? `cta-${data.style}` : false]"
    :style="data.style === 'pill' ? {
      '--pill-color': data.color,
      '--pill-bg-color': data.background,
    } : false"
  >
    <template v-if="data.fn && !data.url">
      <button
        :class="{ active }"
        @click="data.fn"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 's',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="data.external || data.target">
      <a
        :href="data.url"
        :target="data.target"
        :class="{ active }"
        @click="() => { data.fn ? data.fn() : null }"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 's',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="data.url"
        :class="{ active }"
        @click.native="() => { data.fn ? data.fn() : null }"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 's',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Cta',
  components: {
    Label,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  display: inline-block;
  color: $grey-light;
  transition: color 0.4s $ease-custom;
  text-align: left;

  &.router-link-exact-active,
  &.active {
    color: $white;
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
    }
  }

  * {
    pointer-events: none;
  }
}

.cta {

  &-btn {
    margin-top: map-get($spacer, m);

    @include mq(m) {
      margin-top: map-get($spacer, xxl);
    }

    button,
    a {
      border-radius: 15px;
      padding: 10px 12px;
      border: 1px solid currentColor;
    }
  }

  &-pill {

    a,
    button {
      height: 0;
      position: relative;
      padding-bottom: 100%;
      width: 100%;
      color: var(--pill-color, $white);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--pill-bg-color, $black);
        transition: transform 0.4s $ease-custom;
      }

      &>* {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        text-align: center;
        z-index: 1;
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
</style>
