<template>
  <div class="cursor">
    <Label :data="{ value: value, tag: 'div', size: 'm' }" />
  </div>
</template>

<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import Label from '@/components/typo/label';

export default {
  name: 'AppCursor',
  components: {
    Label,
  },
  data() {
    return {
      value: '',
      el: null,
      mouse: {
        x: 0,
        y: 0,
        active: false,
        timeout: null,
        force: false,
        target: null,
      },
    };
  },
  computed: {
    ...mapGetters(['currentColor']),
  },
  mounted() {
    const mouseFn = (e) => {
      if (this.mouse.force) return;
      this.mouse.x = Math.round(e.clientX);
      this.mouse.y = Math.round(e.clientY);
      this.mouse.target = e.target;
      this.mouse.active = true;

      this.el = e.target.dataset.cursor
        ? e.target
        : e.target.closest('[data-cursor]');

      if (this.el && this.el.dataset.cursor !== this.value) {
        this.value = this.el.dataset.cursor;
      }

      if (this.el) {
        this.mouse.active = true;
      } else {
        this.mouse.active = false;

        if (!this.mouse.active) {
          this.mouse.active = true;
        }
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.mouse.active) {
          this.mouse.active = false;
        }
      }, 6000);
    };
    // window.addEventListener('mousemove', mouseFn);
    window.addEventListener('pointermove', mouseFn);

    this.$aion.add(this.setCursor, 'setCursor');

    gsap.set(this.$el, {
      xPercent: -50,
      yPercent: -50,
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    });
  },
  methods: {
    setCursor() {
      if (!this.mouse.active || this.mouse.force) {
        gsap.killTweensOf(this.$el);
        gsap.set(this.$el, {
          autoAlpha: 0,
        });

        document.body.classList.remove('no-cursor');

        return;
      }

      if (
        (this.mouse.y + window.pageYOffset
          <= window.innerHeight - this.$store.state.delta
          || this.mouse.y + window.pageYOffset
            > window.innerHeight - this.$store.state.delta)
        && !this.el
      ) {
        gsap.killTweensOf(this.$el);

        gsap.set(this.$el, {
          autoAlpha: 0,
        });

        document.body.classList.remove('no-cursor');
      } else {
        document.body.classList.add('no-cursor');
        gsap.killTweensOf(this.$el);

        gsap.set(this.$el, {
          autoAlpha: this.mouse.active ? 1 : 0,
          x: this.mouse.x,
          y: this.mouse.y,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  position: fixed;
  top: 0;
  left: 0;
  // white-space: nowrap;
  z-index: 5;
  pointer-events: none;
  color: $white;
  width: auto;

  @include mq(m) {
    max-width: 320px;
  }
  // mix-blend-mode: difference;
}
</style>
